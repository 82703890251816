.partner-logo-bankai-repo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
}

.partner-logo-bankai-repo img {
    padding: 6px 15px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #47474729;
    border-radius: 11px;
    width: 60%;
}

.crop-pricerepo {
    display: flex;
    align-items: center;
    gap: 4px;
}

.crop-pricerepo p {
    font-size: 11px !important;
    color: #909090;
    text-decoration: line-through;
}

.repo-card-bankai-one {
    padding: 3px 10px;
}

.head-text-nade-repo {
    text-align: center;
}

.head-text-nade-repo p {
    letter-spacing: 0px;
    color: #00857B;
    font-size: 17px;
    font-weight: 500;
}

span.repo-span-ban-cvd {
    font-size: 16px;
    color: #007269;
    font-weight: 500;
}

.repo-card-bankai-one p {
    font-weight: 500;
    color: #007269;
    font-size: 14px;
    margin-bottom: 0px;
}

.repo-card-bankai-one p span {
    font-size: 13px;
    color: black;
    font-weight: 400;
}

.details-content-eyedental-bankai {
    background: transparent linear-gradient(131deg, #FFFFFF 0%, #F6FDFF 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px;
    padding: 10px;
    width: 95%;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 10px;
}

.bar-banakia input {
    border: 1px solid #2FDACA !important;
}

.details-content-eyedental-bankai span {
    font-size: 13px;
    font: normal normal 500 12px/18px Nunito;
    letter-spacing: 0px;
}

.text-container {
    font-family: Arial, sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #333;
}

.text-content {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    /* Show 4 lines */
    -webkit-box-orient: vertical;
    position: relative;
    transition: max-height 0.3s ease-in-out;
    font-size: 13px;
    margin-bottom: 0px;
}

.contant-services-repo-tokyo {
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* Two equal-width columns */
    margin-top: 10px;
}

.repo-content-tepo-bank {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 5px;
    padding-left: 12px;
}

.repo-content-tepo-bank:nth-child(1) {
    border-right: 1px dashed #0cb875;
    border-bottom: 1px dashed #0cb875;
}

.repo-content-tepo-bank:nth-child(2) {
    border-left: 1px dashed #0cb875;
    border-bottom: 1px dashed #0cb875;
}

.repo-content-tepo-bank:nth-child(3) {
    border-top: 1px dashed #0cb875;
    border-right: 1px dashed #0cb875;
}

.repo-content-tepo-bank:nth-child(4) {
    border-top: 1px dashed #0cb875;
    border-left: 1px dashed #0cb875;
}

.repo-content-tepo-bank img {
    width: 30px;
}

.our-bankai-services {
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 5px;
}

.banaki-text-input input:disabled {
    background-color: #b6b6b6 !important;
}

.search-bar.bar-banakia svg {
    color: #0cb875;
}
span.eye-text-one {
    font-weight: 400;
    color: #363636;
}
.wrapper-bankai-repo button {
    background: #0CB875 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    border-radius: 6px !important;
}

.our-bankai-services span {
    font-size: 13px;
    font-weight: 500;
}

.form-page textarea {
    width: 100%;
    padding: 9px;
    border-radius: 10px;
    border: none;
    margin-bottom: 15px;
    box-shadow: #009688 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    cursor: pointer;
    font-size: 14px;
}

.card-wrapper-bankai-rpeo {
    background: transparent linear-gradient(115deg, #F6FDFF 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #88888859;
    border: 0.5px solid #007269;
    border-radius: 15px;
    width: 95%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding: 12px 8px;
    gap: 13px;
    /* margin-bottom: 15px; */
    cursor: pointer;
    margin-top: 8px;
    margin-bottom: 8px;
}

.content-repo-doc-ank-ai p {
    margin-bottom: 0px;
    font-size: 13px;
    font-weight: 500;
}

.content-repo-doc-ank-ai h6 {
    margin-bottom: 0px;
    font-size: 14px;
    color: #00684B;
}

p.repo-text-span {
    font-size: 12px;
    -webkit-line-clamp: 2 !important;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 400 !important;
}

.image-repo-doc-banaki {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 50%;
}

.image-repo-doc-banaki img {
    width: 65px;
}

.cluto-repo-repo {
    text-align: center;
    margin-top: 75px;
}

.cluto-repo-repo {
    font-size: 20px;
    font-weight: 500;
}

.repo-proicce-bankai {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
}

.repo-proicce-bankai span {
    color: #1F88FE;
    font-size: 13px;
    font-weight: 500;
}

.repo-proicce-bankai button {
    background: #0CB875 0% 0% no-repeat padding-box;
    border-radius: 5px;
    border: none;
    color: white;
    font-size: 11px;
    padding: 4px 15px;
}

.circle-bank {
    width: 8px;
    background: #0cb875;
    height: 8px;
    border-radius: 50%;
}

.text-content.expanded {
    -webkit-line-clamp: unset;
    /* Show full content */
    max-height: none;
    /* Remove height limit */
}

.view-toggle {
    color: #1f88fe;
    /* Match color from the image */
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
    margin-top: 5px;
    display: inline-block;
}

.view-toggle:hover {
    text-decoration: underline;
}