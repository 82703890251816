.product-wrapper-box-conver {
    display: flex;
    align-items: self-start;
}

.image-product-cov {
    border: 1px solid #007E4D;
    border-radius: 8px;
    position: relative;
    padding: 8px;
    width: 70px;
    cursor: pointer;
}
.cart-discount-count-text p {
    font-size: 11px !important;
    color: #0cb875 !important;
}
.product-name {
    display: flex;
    align-items: center;
    position: relative;
}
.product-name button {
    background: none;
    border: none;
    font-size: 20px;
    color: red;
    /* margin-top: -10px; */
    /* margin-bottom: 10px; */
    position: absolute;
    right: -10px;
    top: -10px;
}
.image-product-cov img {
    aspect-ratio: 3/4;
    object-fit: contain;
    width: 100%;
}
.text-help-box p {
    margin-bottom: 0px;
    font-size: 13px;
    font-weight: 600;
}
.image-data-repo img {
    width: 75px;
}
.need-help-repo-textt {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    margin-top: 25px;
    background: transparent linear-gradient(105deg, #EFFFF9 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 12px;
    padding: 10px;
    gap: 20px;
    margin-bottom: 25px;
}

.text-help-box p {
    margin-bottom: 0px;
}

.product-px-cover-repo img {
    width: 20px;
    position: absolute;
    left: -10px;
    top: -6px;
    height: 20px;
}

.fin-text-repo {
    margin-top: 10px;
}

.payment-text-repo p {
    font: normal normal bold 17px/22px Nunito;
    letter-spacing: 0px;
    color: #007E4D;
}

.price-payment-sum-repo.total-pay-text p {
    font: normal normal 17px/18px !important;
    letter-spacing: 0px;
    color: #0CB875;
    font-weight: 500;
}

.price-payment-sum-repo {
    display: flex;
    justify-content: space-between;
}

.payment-summary-box-repo {
    border-bottom: 0.5px dashed #9A9A9A;
}

.price-payment-sum-repo p {
    font: normal normal 13px/18px Nunito;
    letter-spacing: 0px;
    color: #363636;
    font-weight: 500;
    text-transform: capitalize;
}

.price-payment-sum-repo.total-save-text {
    background: #20948B 0% 0% no-repeat padding-box;
    border-radius: 18px;
    width: 105%;
    margin-left: -6px;
}

.price-payment-sum-repo.total-save-text p {
    margin-bottom: 0px;
    padding: 10px;
    color: white;
}

.price-payment-sum-repo.total-save-text p img {
    margin-right: 5px;
}

.price-payment-sum-repo.total-save-text {
    background: #20948B 0% 0% no-repeat padding-box;
    border-radius: 18px;
}

.payment-repo-cover-box {
    margin-top: 25px;
    background: transparent linear-gradient(133deg, #FFFFFF 0%, #EAFFF7 100%) 0% 0% no-repeat padding-box;
    border: 1px solid #0CB875;
    margin-left: 15px;
    margin-right: 15px;
    padding: 16px 15px;
    border-radius: 15px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.payment-repo-cover-box {
    margin-top: 25px;
}

.remove-btn-repo-cover {
    position: relative;
    left: -5px;
    display: flex;
    align-items: center;
}

.out-stock-cover-wrapper {
    margin-top: 30px;
    padding-left: 12px;
    padding-right: 5px;
}

/* .text-repo-out-stock {
    padding-left: 12px;
} */
.text-repo-out-stock p {
    font: normal normal 15px/16px Nunito;
    letter-spacing: 0px;
    color: #CD0000;
    font-weight: 500;
}

.repo-prod-data-cvoer {
    display: flex;
    justify-content: space-between;
}

.product-name p {
    margin-bottom: 4px;
    font-size: 13px;
    font-weight: 500;
}

.product-price-text p {
    margin-bottom: 0px;
    color: #1F88FE;
    font-weight: 600;
    font-size: 15px;
}

.product-qty p {
    margin-bottom: 0px;
    font-size: 12px;
    font-weight: 600;
    color: #006D43;
}

.count-btns-repo {
    display: flex;
    gap: 5px;
}

button.count-btns-repo {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #007E4D;
    color: black;
    width: 23px;
    height: 23px;
    border-radius: 50% !important;
    padding: 0px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cart-wrapper-repo-cover-box {
    margin-top: 25px;
    padding-left: 15px;
    padding-right: 15px;
}

.price-qty-cove {
    display: flex;
    margin-bottom: 5px;
    flex-direction: column;
}

.ellipsis-text-cart {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    line-height: 16px;
    max-height: 32px;
    text-align: start;
    width: 198px !important;
}

button.count-btns-repo p {
    color: #007E4D !important;
    font-size: 17px;
    margin-bottom: 0px;
    margin-left: 0px;
    font-weight: 500;
}

.product-repo-buttons-repo {
    display: flex;
    justify-content: space-between;
}

hr.product-hr {
    color: inherit;
    border: 0;
    border-top: 0.5px solid #868686;
    opacity: .25;
    width: 97%;
    margin: 1rem auto;
}

.remove-btn-repo-cover button {
    background: none;
    border: none;
    padding: 0px;
    font-size: 14px;
    color: red;
    text-decoration: underline;
    font-weight: 500;
}

.product-data-desc-box {
    width: 100%;
    margin-left: 12px;
}

.product-price-text {
    display: flex;
    gap: 6px;
    align-items: center;
}

.product-price-text span {
    font-size: 12px;
    text-decoration: line-through;
    color: #8D8D8D;
}