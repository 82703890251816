* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
}

/* Container styling */
.container {
    /* margin: -15px auto; */
    /* padding: 10px; */
}

/* Date tabs styling */
.date-tabs {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    gap: 10px;
}

.date-tab p {
    margin-bottom: 0px;
}

.date-tab {
    flex: 1 1;
    padding: 8px;
    text-align: center;
    border-radius: 10px;
    color: #555;
    background-color: #e0e0e0;
    cursor: pointer;
    gap: 10px;
    font-size: 13px;
    font-weight: 500;
    padding-top: 5px;
    padding-bottom: 5px;
}

.slot-clover-tabs-repo li button {
    padding: 5px 30px;
    font-weight: 500;
    font-size: 14px !important;
    margin-left: 0px !important;
}

.date-tab span {
    font-size: 10px;
    font-weight: 500;
}

.date-tab.active {
    background-color: #3b82f6;
    color: white;
}

/* Time slot sections */
.time-slot-section {
    margin-bottom: 20px;
    background-color: #ffffff;
    padding: 0px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.time-slot-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    border-bottom: 1px solid #eaeaea;
    padding: 10px;
    padding-top: 15px;
    padding-bottom: 7px;
}

.time-slot-header h3 {
    font-size: 13px;
    color: #888888;
}

.time-slot-header .slot-count {
    font-size: 10px;
    color: #198754;
    background: #dbf3e8;
    padding: 4px 13px;
    border-radius: 15px;
    /* margin-bottom: 10px; */
    font-weight: 500;
}

/* Time slot buttons */
.time-slots {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    /* justify-content: center; */
    padding: 5px;
    padding-bottom: 12px;
}

.slot {
    padding: 8px 10px;
    text-align: center;
    border-radius: 8px;
    font-size: 0.9rem;
    color: #333;
    background-color: white;
    cursor: pointer;
    font-size: 10px;
}

.datepicker-calendar {
    position: absolute;
    z-index: 10;
    left: 0px;
    /* Adjust as needed */
}

p.no-slots-text {
    margin-bottom: 0px;
    font-weight: 500;
    position: relative;
    left: 28%;
}
span.repo-bank-ichigo {
    top: 210px !important;
    color: dimgray !important;
    text-decoration: none !important;
    text-align: center !important;
    left: 83px;
    background: white;
}
p.no-medicine-found span {
    position: absolute;
    /* bottom: -64px; */
    top: 235px;
    /* left: 20px; */
    color: #0d6efd;
    text-decoration: underline;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
}
.repo-cal-clover {
    position: relative;
    display: flex;
    align-items: self-start;
    margin-bottom: 15px;
    margin-top: 20px;
    flex-direction: column;
    justify-content: center;
}
.repo-cal-clover span {
    font-size: 13px;
    margin-top: 3px;
    font-weight: 500;
}

.slot.available {
    /* background-color: #e0f7fa; */
    color: #00796b;
}

.slot.unavailable {
    background-color: #f5f5f5;
    color: #9e9e9e;
    cursor: not-allowed;
}

.slot.selected {
    background-color: #00796b;
    color: white;
}
p.repo-selcted-text-clover-epr {
    position: absolute;
    bottom: 30%;
    left: 25px;
    font-size: 15px;
    font-weight: 400;
}
.datepicker-calendar .react-date-picker__inputGroup {
    display: none !important;
}

.datepicker-calendar .react-date-picker__wrapper {
    box-shadow: none !important;
    border: 0px solid #fffefe !important;
}

.datepicker-calendar .react-date-picker__calendar-button {
    position: absolute !important;
    right: 0px !important;
}

.datepicker-calendar .react-date-picker__calendar-button:hover .react-date-picker__button:enabled:hover .react-date-picker__button__icon {
    border: transparent !important;
    stroke: none !important;
}

.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
    stroke: transparent;
}

.repo-cal-clover svg {
    position: absolute;
    right: 24px;
}

.datepicker-calendar .react-date-picker__button svg {
    width: 20px;
    margin-right: 10px;
    position: absolute;
    left: 250px;
    top: 0px;
}

.datepicker-calendar .react-date-picker__button svg {
    width: 30px;
    margin-right: 10px;
    position: absolute;
    left: 246px;
    top: 0px;
    stroke: transparent;
    height: 24px;
}

.repo-cal-clover h6 {
    margin-bottom: 0px;
}

.slot.available {
    color: #198754;
    font-weight: bold;
    border: 1px solid #198754;
}

.slot.unavailable {
    color: #bbb;
    background-color: #f0f0f0;
    cursor: not-allowed;
}

.slot.selected {
    background-color: #198754;
    color: white;
}