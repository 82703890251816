.home-prescription-text-boxs {
    margin-top: 15px;
    padding-left: 10px;
}

.home-prescription-text-boxs p {
    margin-bottom: 0px;
    font-size: 14px;
    font-weight: 500;
}

.home-prescription-text-boxs span {
    color: red;
    font-size: 13px;
    font-weight: 500;
}

.file-manager-data-boxs-repo {
    display: flex;
    gap: 10px;
    padding: 10px;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
}

.file-manager-data-boxs-repo-left,
.file-manager-data-boxs-repo-right {
    position: relative;
    display: flex;
    align-items: center;
}

.file-manager-data-boxs-repo-left input,
.file-manager-data-boxs-repo-right input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.file-manager-data-boxs-repo-left label,
.file-manager-data-boxs-repo-right label {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 10px;
    border-radius: 5px;
    cursor: pointer;
    gap: 10px;
    font-size: 12px;
}

.file-manager-data-boxs-repo-left label {
    background-color: #007E4D;
    color: white;
}

.file-manager-data-boxs-repo-right label {
    background-color: #FFFFFF;
    color: #333;
    border: 1px solid #ccc;
}

.file-manager-data-boxs-repo-left img,
.file-manager-data-boxs-repo-right img {
    width: 20px;
    height: 20px;
}

.new-prescription-box-wrapper-data {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    margin-top: 5px;
    padding: 10px;
}

.presc-header p {
    margin-bottom: 0px;
    font-weight: 500;
}
.contine-btn-repo-presc button {
    background: #0CB875 0% 0% no-repeat padding-box;
    border-radius: 8px;
    border: none;
    padding: 8px 20px;
    color: white;
    font-weight: 500;
}
.contine-btn-repo-presc {
    text-align: center;
    background: white;
    padding-bottom: 15px;
    padding-top: 10px;
}
.presc-header {
    padding: 10px;
    padding-bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.prescription-box {
    position: relative;
    /* border: 1px solid #ccc; */
    /* padding: 10px; */
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.close-button {
    position: absolute;
    top: -8px;
    right: -7px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    background: white;
    border: 1px solid;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    padding-left: 4px;
}

.prescription-image {
    width: 85px;
    height: 85px;
    object-fit: cover;
    margin-bottom: 10px;
    border: 1px solid grey;
    border-radius: 5px;
    padding: 5px;
}

img.prescription-image.pdf-icon {
    padding: 15px;
}

.prescription-name {
    font-size: 13px;
    margin-bottom: 10px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    line-height: 1.2;
    max-height: 2.4em;
    text-align: start;
    word-wrap: break-word;
    width: 96px;
    font-weight: 500;
}

.image-box-data-repo-cont {
    position: relative;
}

.cbx {
    margin-top: auto;
    position: absolute;
    bottom: 15px;
    right: -2px;
    width: 20px;
    height: 18px;
}

.line-header-repo-line-repo {
    height: 1px;
    width: 95%;
    background: #707070;
    margin: 0 auto;
}

.prescriptionData-data-box-cover {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    margin-top: 5px;
    padding: 10px;
}

.not-have-presciption {
    display: flex;
    align-items: center;
    background: transparent linear-gradient(103deg, #EBFFF7 0%, #F9FBFF 51%, #EAEFFF 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #FFFFFF;
    border-radius: 10px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 5px;
    margin-top: 15px;
    cursor: pointer;
}

.data-box-image-presc img {
    width: 100px;
}

.data-box-text-presciption span {
    font: normal normal 800 14px/18px Nunito;
    letter-spacing: 0px;
    color: #005581;
}

.data-box-text-presciption {
    padding-left: 11px;
}

.data-box-text-presciption p {
    margin-bottom: 4px;
    font: normal normal bold 14px/16px Nunito;
    letter-spacing: 0px;
    color: #010000;
}
.contine-btn-repo-presc button:disabled{
    background-color: #707070 !important;
    color: white !important;
}
.valid-prescription-box-cover {
    background: #DDE9FF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 3px #80808029;
    border-radius: 10px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 30px;
    padding: 15px;
    margin-bottom: 25px;
}

.valide-image-data-box-repo {
    display: flex;
    justify-content: center;
}

.valide-image-data-box-repo img {
    width: 90%;
}

.valid-text-repo-presc p {
    font: normal normal bold 15px/19px Nunito;
    letter-spacing: 0px;
    color: #363636;
    margin-bottom: 5px;
}

.valid-para-prescription-data p {
    text-align: center;
    font: normal normal 500 13px/18px Nunito;
    letter-spacing: 0px;
    color: #001110;
    margin-bottom: 5px;
}




/* From Uiverse.io by vishnupprajapat */
.checkbox-wrapper-46 input[type="checkbox"] {
    display: none;
    visibility: hidden;
}

.checkbox-wrapper-46 .cbx {
    margin: auto;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
}

.checkbox-wrapper-46 .cbx span {
    display: inline-block;
    vertical-align: middle;
    transform: translate3d(0, 0, 0);
}

.checkbox-wrapper-46 .cbx span:first-child {
    position: relative;
    width: 18px;
    height: 18px;
    border-radius: 3px;
    transform: scale(1);
    vertical-align: middle;
    border: 1px solid #009589;
    transition: all 0.2s ease;
}

.checkbox-wrapper-46 .cbx span:first-child svg {
    position: absolute;
    top: 3px;
    left: 2px;
    fill: none;
    stroke: #ffffff;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 16px;
    stroke-dashoffset: 16px;
    transition: all 0.3s ease;
    transition-delay: 0.1s;
    transform: translate3d(0, 0, 0);
}

.checkbox-wrapper-46 .cbx span:first-child:before {
    content: "";
    width: 100%;
    height: 100%;
    background: #009688;
    display: block;
    transform: scale(0);
    opacity: 1;
    border-radius: 50%;
}

.checkbox-wrapper-46 .cbx span:last-child {
    padding-left: 8px;
}

.checkbox-wrapper-46 .cbx:hover span:first-child {
    border-color: #009688;
}

.checkbox-wrapper-46 .inp-cbx:checked+.cbx span:first-child {
    background: #009688;
    border-color: #009688;
    animation: wave-46 0.4s ease;
}

.checkbox-wrapper-46 .inp-cbx:checked+.cbx span:first-child svg {
    stroke-dashoffset: 0;
}

.checkbox-wrapper-46 .inp-cbx:checked+.cbx span:first-child:before {
    transform: scale(3.5);
    opacity: 0;
    transition: all 0.6s ease;
}

@keyframes wave-46 {
    50% {
        transform: scale(0.9);
    }
}


/* upload btn */
/* From Uiverse.io by Creatlydev */
.button {
    --bg: linear-gradient(180deg, hsla(0, 0%, 100%, .1), hsla(0, 0%, 100%, 0)), hsl(158.57deg 100% 24.71%);
    box-shadow: 0 1px 2px #25114f66, 0 0 0 1px hsl(158.57deg 100% 24.71%);
    background-color: transparent;
    border: none;
    line-height: 1;
    display: flex;
    align-items: center;
    gap: .5rem;
    /* padding: .25rem .5rem; */
    cursor: pointer;
    padding-right: 1rem;
    font-weight: 600;
    color: #fff;
    background: var(--bg);
    white-space: nowrap;
    border-radius: 6px;
    border: 1px solid #fff3;
}
span.btn__icon svg {
    width: 20px;
}
span.btn__text {
    font-size: 12px;
}