.package-image-repo-buy img {
    width: 97%;
    margin: 0 auto;
}

.package-image-repo-buy {
    margin: 0 auto;
    display: flex;
}

.repo-buy-now-repo-loki {
    width: 98%;
    margin: 0 auto;
}

.package-details-loki p {
    margin: 0 auto;
    margin-bottom: 0px;
    margin-top: 10px;
    font-size: 14px;
    width: 97%;
}

.repo-benefit-loki-buy {
    margin-top: 15px;
    margin-left: 10px;
}

.repo-package-cover-loki {
    display: flex;
    align-items: self-start;
    flex-direction: column;
    justify-content: flex-start;
}

.repo-card-drive-loki {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 15px;
    margin-left: -5px;
    margin-right: 3px;
    border-left: 3px solid green;
    position: relative;
}

.repo-image-iron {
    position: absolute;
    right: 0px;
    top: 0px;
    background: white;
    padding: 6px;
    border-radius: 0px 10px 0px 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.repo-text-desc-para {
    margin-top: 15px;
}

.repo-line-loki {
    width: 45px;
    height: 2px;
    background: #007e4d;
}

.repo-text-desc-para p svg {
    color: green;
    font-size: 18px;
    margin-top: -2px;
    margin-right: 5px;
}

.repo-card-drive-loki img {
    width: 21px;
}

.title-loki-repo h6 {
    font-size: 17px;
    margin-bottom: 15px;
}

.repo-text-desc-para p {
    margin-bottom: 5px;
    font-size: 13px;
}

.repo-line-loki-text {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    border-bottom: 2px solid green;
}

.price-pack-loki-repo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    gap: 3px;
}

.repo-otp-clover-loki {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    margin-top: 25px;
}

.otp-timer button {
    background: none;
    border: none;
    color: #0d6efd;
    text-decoration: underline;
    margin-top: 8px;
    font-size: 14px;
}

.otp-timer p {
    margin-top: 8px;
    font-size: 14px;
    color: #6c757d;
    text-align: center;
}

.otp-timer {
    text-align: center;
}

.genrate-otp-btn {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 40px;
}

.genrate-otp-btn button {
    background: #0d6efd;
    padding: 8px 26px;
    border: none;
    border-radius: 10px;
    color: white;
    font-size: 15px;
}

.repo-clone-text p {
    font-weight: 500;
    font-size: 16px;
}

.verify-otp-repo-loki {
    margin-top: 15px;
    margin-bottom: 35px;
    display: flex;
    justify-content: center;
}

.verify-otp-repo-loki button {
    border: none;
    padding: 8px 35px;
    background: #0d6efd;
    color: white;
    border-radius: 10px;
}

.repo-clone-text span {
    font-size: 12px;
    margin-top: 2px;
    color: gray;
    text-decoration: line-through;
}

.price-pack-loki-repo p {
    margin-bottom: 0px;
    font-weight: 500;
    font-size: 15px;
}

.price-pack-loki-repo p {
    margin-bottom: 0px;
}

.repo-clone-text {
    display: flex;
    align-items: center;
    gap: 5px;
}