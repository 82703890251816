.loader-repo {
    width: 100px;
    height: 13px;
    -webkit-mask: radial-gradient(circle closest-side, #0cb875 94%, #0000) left / 20% 100%;
    background: linear-gradient(#0cb875 0 0) left / 0% 100% no-repeat #ddd;
    animation: l17 1.5s infinite steps(6);
}

@keyframes l17 {
    100% {
        background-size: 120% 100%
    }
}
.repo-data-load{
    display: flex;
    justify-content: center;
}