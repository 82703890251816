p.Reduc-late {
    font-size: 15px;
    margin-bottom: 0px;
}

.form-group-depo {
    display: flex;
    flex-direction: column;
    gap: 3px;
    margin-bottom: 10px;
}

.confirmation-buttons-repo-box{
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
}
.lab-order-details-wrapper {
    padding: 10px;
}
input#cancelDate::placeholder{
    color: black !important;
    font-size: 15px;
}