.banner {
    width: 97%;
    /* max-width: 800px; */
    /* background: #e2f3ff; */
    display: flex;
    align-items: center;
    /* padding: 20px; */
    /* border-radius: 10px; */
    margin-top: 20px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    margin: 0 auto;
    margin-top: 20px;
}

.banner img {
    width: 100%;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    /* border-radius: 50%; */
    margin-right: 20px;
}
.denatl-box img {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 10px;
}
.other-partner-repo-clover {
    margin-top: 20px;
    cursor: pointer;
}
.wrapper-wellness-loki-para {
    margin-bottom: 15px;
}
.other-partner-repo-clover p {
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 500;
}

.other-partner-repo-clover button {
    background: #198754;
    border: none;
    font-size: 14px;
    font-weight: 500;
    /* text-decoration: underline; */
    color: white;
    padding: 8px 25px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 3px, rgba(0, 0, 0, 0.3) 0px 7px 5px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.banner-content {
    display: flex;
    flex-direction: column;
}

.banner-content h2 {
    font-size: 24px;
    color: #333;
    margin: 0;
}

.banner-content p {
    font-size: 14px;
    color: #666;
    margin: 5px 0 0;
}

/* Partners Section */
.partners-section {
    text-align: center;
    margin-top: 20px;
}

.partners-section h3 {
    font-size: 20px;
    color: #333;
    /* margin-bottom: 20px; */
}

.partners-logos {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    justify-items: center;
    /* Center items horizontally in their grid cells */
    padding: 6px;
}

.partner-card:nth-child(3) {
    grid-column: span 2;
    justify-self: center;
    width: 70%;
}


.partner-card {
    /* width: 150px; */
    /* height: 80px; */
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #D9D9D9;
    border: 1px solid #20948B;
    border-radius: 5px;
    flex-direction: column;
    padding: 5px;
    padding-top: 15px;
    gap: 10px;
    padding-bottom: 10px;
    cursor: pointer;
}

.partner-card-dental {
    width: 145px;
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #D9D9D9;
    border: 1px solid #20948B;
    border-radius: 5px;
    flex-direction: column;
    padding: 5px;
    padding-top: 15px;
    gap: 10px;
    padding-bottom: 10px;
    cursor: pointer;
}

.dot-line {
    width: 75px;
    height: 0px;
    border: 1px dashed #20948B;
    margin-top: -5px;
}

.form-eye-image-box {
    /* padding: 40px; */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 2px #00000029;
    border-radius: 5px;
    /* width: 50%; */
}

.form-banner-eye {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 12px;
    padding: 20px 40px;
    width: 90% !important;
    flex-direction: column;
    gap: 10px;
}

.form-eye-repo input {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #75757529;
    border: 0.5px solid #20948B;
    border-radius: 6px;
    margin-top: 5px;
}

.form-eye-repo label {
    font-size: 14px;
}

.banner.form-banner-eye p {
    margin-bottom: 0px;
    font-size: 15px;
    font-weight: 500;
}

.form-banner-eye img {
    padding: 25px;
}

.part-repo-text-dot {
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
    margin-bottom: 20px;
}

.partner-card img {
    /* max-width: 100px; */
    /* max-height: 50px; */
    width: 90%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #47474729;
    padding: 10px;
    border-radius: 5px;
}
.other-partner-repo-clover img {
    width: 95%;
    margin-bottom: 20px;
}
.partner-card-dental img {
    max-width: 140px;
    max-height: 50px;
    /* width: 90%; */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #47474729;
    padding: 10px;
    border-radius: 5px;
}

.partner-card p {
    font-size: 12px;
    margin-bottom: 0px;
    font-weight: 500;
}

.partner-card-dental p {
    font-size: 12px;
    margin-bottom: 0px;
    font-weight: 500;
}