.login-form-section-repo form {
    padding: 0px 15px;
}

.auth-form-input-box label {
    font-size: 13px;
}

.auth-form-input-box {
    margin-bottom: 12px;
}

.auth-form-input-box input {
    padding: 10px 10px;
    border-radius: 7px;
    font-size: 12px;
    border: 1px solid #69696921;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

span.auth-error-msg {
    font-size: 12px;
    color: red;
}