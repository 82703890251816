.doctor-text-repo {
    padding: 20px;
}

.doctor-text-repo p {
    margin-bottom: 0px;
    font-weight: 500;
    font-size: 15px;
}
.repo-west {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.doctor-form-cover-repo {
    padding: 0px 20px;
}

.input-cover-form label {
    margin-bottom: 6px;
    font: normal normal 500 14px/18px Nunito;
    letter-spacing: 0px;
    color: #363636;
}
.doctor-submit-btn {
    text-align: center;
    margin-bottom: 20px;
}

.doctor-submit-btn button {
    background: #0CB875 0% 0% no-repeat padding-box;
    border-radius: 8px;
    padding: 8px 30px;
    color: white;
    border: none;
    font-weight: 500;
}
.input-cover-form input::placeholder {
    font-size: 13px;
    color: #9A9A9A;
}
.icons-repo-cover-de.depart-icon img {
    width: 38px;
}
.input-cover-form input {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0.5px solid #007E4D;
    border-radius: 6px;
    padding: 7px;
}
.input-cover-form textarea {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0.5px solid #007E4D;
    border-radius: 6px;
    padding: 7px;
}
.input-cover-form {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}