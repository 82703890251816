.order-status-data-repo-wrapper svg {
    width: 170px !important;
}

.order-status-data-repo-wrapper p {
    font-size: 17px;
    font-weight: 500;
    color: #007E4D;
    margin-bottom: 5px;
}
.order-status-data-repo-wrapper.pending-anime {
    margin-bottom: 20px;
}
.thank-rep-wrapper {
    margin-bottom: 40px;
}
.booked-test-wrapper.repo-cover-thank-data.mt-3 {
    width: 96%;
    margin: 0 auto;
    margin-bottom: 75px;
}
.telewrapper-cont-text-cover {
    padding: 15px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    width: 97%;
    margin: 0 auto;
    margin-top: 20px;
    border-radius: 10px;
    margin-bottom: 34%;
}
.pending-anime p {
    color: #ffc107 !important;
    margin-top: 20px !important;
}
/* .thank-rep-wrapper {
    height: 75.5vh;
} */
span.book-time-date-te {
    font-size: 17px;
    font-weight: 500;
}
.cancelled-anime p {
    color: red !important;
    margin-top: 20px !important;
}

.cancelled-anime svg {
    width: 100px !important;
}

.pending-anime svg {
    width: 100px !important;
}

.order-status-data-repo-wrapper {
    text-align: center;
}

.order-shipping-address-wrapper {
    padding: 15px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    padding-top: 5px;
}

.heading-ship p {
    margin-bottom: 3px;
    font-size: 16px;
    font-weight: 500;
}
.order-placed-data-buttons.sticky-bottom {
    background-color: white;
    padding: 15px 0px;
    display: flex;
    justify-content: space-around;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
button.back-home-order {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #0CB875;
    border-radius: 8px;
    font-size: 13px;
    font-weight: 500;
    padding: 8px 16px;
}
button.details-home-order {
    background: #0CB875 0% 0% no-repeat padding-box;
    border-radius: 8px;
    border: none;
    color: white;
    font-size: 13px;
    font-weight: 500;
    padding: 8px 16px;
}
.ship-address-content p {
    margin-bottom: 2px;
    font-size: 13px;
}

.product-content-box-repo-wrapper-box {
    display: flex;
    align-items: center;
    gap: 10px;
}

.order-shiping-order-wrapper {
    padding: 15px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 15px;
    padding-top: 5px;
}

.repo-recent-heade p {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0px;
    padding-left: 15px;
    padding-top: 15px;
}

.right-side-box-order p {
    margin-bottom: 4px;
    font-size: 13px;
    font-weight: 500;
}

.pridc-order-repo {
    display: flex;
    align-items: center;
    gap: 5px;
}

.pridc-order-repo p {
    margin-bottom: 0px;
    color: #1F88FE;
    font-weight: 500;
}

p.qty-order-qty {
    font: normal normal 600 12px/14px Nunito;
    letter-spacing: 0px;
    color: #006D43;
}

.left-side-box-order img {
    border: 0.5px solid #007E4D;
    border-radius: 6px;
    aspect-ratio: 3/2;
    width: 50px;
    height: 75px;
    padding: 5px;
    margin-bottom: 5px;
}

.pridc-order-repo span {
    color: grey;
    font-size: 12px;
    text-decoration: line-through;
}

.left-side-box-order {
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.order-status-data-repo-wrapper.pending-anime span {
    font-size: 13px;
    padding: 10px;
    display: flex;
    padding-bottom: 0px;
    padding-top: 0px;
}
.order-head-item-text p {
    font-size: 16px;
    font-weight: 500;
    padding-left: 10px;
}

.left-side-box-order p {
    margin-bottom: 0px;
    font-size: 10px;
    color: #1F88FE;
    font-weight: 500;
}