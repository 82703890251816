.modal-header {
    border-bottom: none;
    text-align: center;
}

.modal-title {
    color: #2c8e38 !important;
    /* Green color for title */
    font-weight: bold;
}

.cancel-order-form-box {
    --_clr-primary: #666;
    --_clr-hover: #127acf;
    --_clr-checked: black;
}

.foot-cancel-repo.modal-footer {
    justify-content: center !important;
    border-top: none !important;
}

.other-input {
    display: flex;
    flex-direction: column;
    align-items: self-start !important;
}

.confirmation-message img {
    width: 60px !important;
    margin-top: 15px !important;
    text-align: center;
    /* display: flex; */
    margin-bottom: 15px !important;
}

.confirmation-message p {
    font-size: 14px;
    font-weight: 500;
}

.confirmation-message {
    text-align: center;
}

button.yes-modal-data.btn.btn-primary {
    padding: 5px 15px;
    background: white;
    color: black;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #FF5959;
    border-radius: 8px;
    font-size: 14px;
}

button.no-modal-data.btn.btn-primary {
    padding: 5px 15px;
    background: white;
    color: black;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #0CB875;
    border-radius: 8px;
    font-size: 14px;
}

.confirmation-buttons {
    display: flex;
    justify-content: space-around;
}

.cancel-order-image {
    display: flex !important;
    justify-content: center !important;
}

.head-canel-repo {
    border-bottom: none !important;
}

.repo-class-cancel {
    padding: 10px 10px !important;
    padding-top: 5px !important;
}

.form-check-label {
    font-size: 16px;
    color: #4a4a4a;
    /* Dark gray for text */
    margin-left: 10px;
}

.form-check-input {
    margin-right: 15px;
    accent-color: #2c8e38;
    /* Green for radio buttons */
}

.reason-title.modal-title.h4 {
    font-size: 17px;
}

.modal-footer {
    justify-content: center;
    border-top: none;
}

.modal-footer .btn-danger {
    background-color: #ff6b6b;
    border-color: #ff6b6b;
    padding: 6px 30px;
    font-size: 15px;
    border-radius: 5px;
}

.cancel-order-form-box label {
    font-size: 14px;
}

.cancel-order-form-box>div {
    --_clr-current: var(--_clr-primary);
}

.cancel-order-form-box label {
    cursor: pointer;
    color: var(--_clr-current);
    transition: color 150ms ease-in-out;
}

/* styled radio */
.cancel-order-form-box input[type="radio"] {
    appearance: none;
    outline: none;
    width: 20px;
    height: 20px;
    aspect-ratio: 1;
    padding: 0.25rem;
    background: transparent;
    border: 1px solid var(--_clr-current);
    border-radius: 50%;
    display: grid;
    place-content: center;
    cursor: pointer;
    position: relative;
}

.cancel-order-form-box input[type="radio"]::after {
    content: "";
    position: absolute;
    inset: 0.25rem;
    opacity: 0;
    scale: 0;
    transition:
        opacity 150ms ease-in-out,
        scale 150ms ease-in-out;
    background-color: var(--_clr-checked);
    border-radius: inherit;
}

form.cancel-order-form-box {
    display: flex;
    justify-content: space-between;
}

.city input {
    width: 100%;
}

.city {
    display: flex;
    flex-direction: column;
}

.fomr-repo-box-data-cove label {
    margin-top: 12px;
    margin-bottom: 4px;
}

.state-repo-box {
    display: flex;
    /* width: 41%; */
    gap: 10px;
}
.loading-spinner {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 30px;
    gap: 15px;
}
.fomr-repo-box-data-cove {
    display: flex;
    flex-direction: column;
}

.state {
    display: flex;
    flex-direction: column;
}

.state input {
    width: 100%;
}

.cancel-order-form-box {
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    gap: 15px;
    flex-direction: column;
}

.fomr-repo-box-data-cove input {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0.5px solid #007E4D;
    border-radius: 6px;
    padding: 8px;
    font-size: 14px;
}

.fomr-repo-box-data-cove input:focus-visible {
    outline: 0.5px solid #007E4D;
}

.fomr-repo-box-data-cove label {
    font: normal normal 400 15px/18px Nunito;
    letter-spacing: 0px;
    color: #363636;
    margin-left: 4px;
}

form.my-form button {
    background: none;
    background: #0CB875 0% 0% no-repeat padding-box;
    border: 1px solid #0CB875;
    border-radius: 8px;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 15px;
    padding: 6px 20px;
    margin: 0 auto;
    margin-top: 20px;
    color: white;
}

.fomr-repo-box-data-cove input::placeholder {
    font-size: 13px;
}

.cancel-order-form-box>div {
    display: flex;
    align-items: center;
    gap: 10px;
}

.fomr-repo-box-data-cove {
    margin-top: 10px;
}

.cancel-order-form-box label:hover,
.cancel-order-form-box input[type="radio"]:focus-visible,
.cancel-order-form-box input[type="radio"]:focus-visible+label,
.cancel-order-form-box input[type="radio"]:hover,
.cancel-order-form-box input[type="radio"]:hover+label {
    --_clr-current: var(--_clr-hover);
}

.cancel-order-form-box input[type="radio"]:focus-visible::after,
.cancel-order-form-box input[type="radio"]:hover::after {
    opacity: 0.5;
    scale: 1;
    background-color: var(--_clr-hover);
}

.cancel-order-form-box input[type="radio"]:checked+label:not(:hover),
.cancel-order-form-box input[type="radio"]:checked:not(:hover) {
    --_clr-current: var(--_clr-checked);
}

.cancel-order-form-box input[type="radio"]:checked::after {
    opacity: 1;
    scale: 1;
}

.cancel-order-form-box input[type="radio"]:checked {
    border-color: var(--_clr-checked);
    /* Ensure border remains checked color */
}

.cancel-order-form-box input[type="radio"]:checked::after {
    background-color: var(--_clr-checked);
    /* Ensure the checked color remains */
    opacity: 1;
    /* Keep it fully visible */
    scale: 1;
    /* Prevent any scaling */
}

.cancel-order-form-box input[type="radio"]:checked:hover {
    border-color: var(--_clr-checked);
    /* Prevent border color change on hover */
}

p.green {
    font-size: 13px;
    margin-bottom: 0px;
    margin-top: 2px;
    font-weight: 500;
    color: #007e51;
}

p.red {
    font-size: 13px;
    margin-bottom: 0px;
    margin-top: 2px;
    font-weight: 500;
    color: red;
}

.cancel-order-form-box input[type="radio"]:checked:hover::after {
    background-color: var(--_clr-checked);
    /* Prevent background color change on hover */
    opacity: 1;
    /* Keep opacity full */
    scale: 1;
    /* Prevent scale change */
}

.cancel-order-form-box input[type="radio"]:checked:hover+label {
    --_clr-current: var(--_clr-checked);
    /* Prevent label color change on hover */
}