.address-wrapper-box {
    padding: 20px;
    padding-top: 10px;
    padding-bottom: 0px;
}
.address-btn-foot-repo.sticky-bottom {
    text-align: center;
    background: white;
    padding-top: 15px;
    padding-bottom: 15px;
}
.address-btn-foot-repo.sticky-bottom button {
    background: #0CB875 0% 0% no-repeat padding-box;
    border-radius: 8px;
    border: none;
    color: white;
    font-size: 14px;
    padding: 8px 35px;
    font-weight: 500;
}
/* From Uiverse.io by cbolson */
/* From Uiverse.io by cbolson */
.radio-type-form-box {
    --_clr-primary: #666;
    --_clr-hover: #127acf;
    --_clr-checked: #0CB875;
}

.up-text {
    font: normal normal 400 16px/19px Nunito;
    letter-spacing: 0px;
    color: #002522;
    margin-bottom: 20px;
}

.address-type-como p {
    font: normal normal 500 16px/19px Nunito;
    letter-spacing: 0px;
    color: #363636;
    margin-bottom: 12px;
}

.radio-type-form-box>div {
    --_clr-current: var(--_clr-primary);
}

.radio-type-form-box label {
    cursor: pointer;
    color: var(--_clr-current);
    transition: color 150ms ease-in-out;
}

/* styled radio */
.radio-type-form-box input[type="radio"] {
    appearance: none;
    outline: none;
    width: 20px;
    height: 20px;
    aspect-ratio: 1;
    padding: 0.25rem;
    background: transparent;
    border: 1px solid var(--_clr-current);
    border-radius: 50%;
    display: grid;
    place-content: center;
    cursor: pointer;
    position: relative;
}

.radio-type-form-box input[type="radio"]::after {
    content: "";
    position: absolute;
    inset: 0.25rem;
    opacity: 0;
    scale: 0;
    transition:
        opacity 150ms ease-in-out,
        scale 150ms ease-in-out;
    background-color: var(--_clr-checked);
    border-radius: inherit;
}

form.radio-type-form-box {
    display: flex;
    justify-content: space-between;
}

.city input {
    width: 100%;
}

.city {
    display: flex;
    flex-direction: column;
}

.fomr-repo-box-data-cove label {
    margin-top: 12px;
    margin-bottom: 4px;
}

.state-repo-box {
    display: flex;
    /* width: 41%; */
    gap: 10px;
}

.fomr-repo-box-data-cove {
    display: flex;
    flex-direction: column;
}

.state {
    display: flex;
    flex-direction: column;
}

.state input {
    width: 100%;
}

.radio-type-form-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.fomr-repo-box-data-cove input {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0.5px solid #007E4D;
    border-radius: 6px;
    padding: 8px;
    font-size: 14px;
}

.state-repo-box .city input[type="text"] {
    text-transform: capitalize !important;
}

.fomr-repo-box-data-cove input:focus-visible {
    outline: 0.5px solid #007E4D;
}

.fomr-repo-box-data-cove label {
    font: normal normal 400 15px/18px Nunito;
    letter-spacing: 0px;
    color: #363636;
    margin-left: 4px;
}

form.my-form button {
    background: none;
    background: #0CB875 0% 0% no-repeat padding-box;
    border: 1px solid #0CB875;
    border-radius: 8px;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 15px;
    padding: 6px 20px;
    margin: 0 auto;
    margin-top: 20px;
    color: white;
}

.fomr-repo-box-data-cove input::placeholder {
    font-size: 13px;
}

.radio-type-form-box>div {
    display: flex;
    align-items: center;
    gap: 10px;
}

.fomr-repo-box-data-cove {
    margin-top: 10px;
}

.radio-type-form-box label:hover,
.radio-type-form-box input[type="radio"]:focus-visible,
.radio-type-form-box input[type="radio"]:focus-visible+label,
.radio-type-form-box input[type="radio"]:hover,
.radio-type-form-box input[type="radio"]:hover+label {
    --_clr-current: var(--_clr-hover);
}

.radio-type-form-box input[type="radio"]:focus-visible::after,
.radio-type-form-box input[type="radio"]:hover::after {
    opacity: 0.5;
    scale: 1;
    background-color: var(--_clr-hover);
}

.radio-type-form-box input[type="radio"]:checked+label:not(:hover),
.radio-type-form-box input[type="radio"]:checked:not(:hover) {
    --_clr-current: var(--_clr-checked);
}

.radio-type-form-box input[type="radio"]:checked::after {
    opacity: 1;
    scale: 1;
}

.radio-type-form-box input[type="radio"]:checked {
    border-color: var(--_clr-checked);
    /* Ensure border remains checked color */
}

.radio-type-form-box input[type="radio"]:checked::after {
    background-color: var(--_clr-checked);
    /* Ensure the checked color remains */
    opacity: 1;
    /* Keep it fully visible */
    scale: 1;
    /* Prevent any scaling */
}

.radio-type-form-box input[type="radio"]:checked:hover {
    border-color: var(--_clr-checked);
    /* Prevent border color change on hover */
}
p.green {
    font-size: 13px;
    margin-bottom: 0px;
    margin-top: 2px;
    font-weight: 500;
    color: #007e51;
}
p.red {
    font-size: 13px;
    margin-bottom: 0px;
    margin-top: 2px;
    font-weight: 500;
    color: red;
}
.radio-type-form-box input[type="radio"]:checked:hover::after {
    background-color: var(--_clr-checked);
    /* Prevent background color change on hover */
    opacity: 1;
    /* Keep opacity full */
    scale: 1;
    /* Prevent scale change */
}

.radio-type-form-box input[type="radio"]:checked:hover+label {
    --_clr-current: var(--_clr-checked);
    /* Prevent label color change on hover */
}


.inputcontainer {
    position: relative;
  }
  
  input {
    width: 100%;
    font-size: 20px;
    box-sizing: border-box;
  }
  
  .icon-container {
    position: absolute;
    right: 10px;
    top: calc(50% - 10px);
  }
  .loader {
    position: relative;
    height: 20px;
    width: 20px;
    display: inline-block;
    animation: around 5.4s infinite;
  }
  
  @keyframes around {
    0% {
      transform: rotate(0deg)
    }
    100% {
      transform: rotate(360deg)
    }
  }
  
  .loader::after, .loader::before {
    content: "";
    background: white;
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 100%;
    border-width: 2px;
    border-color: #333 #333 transparent transparent;
    border-style: solid;
    border-radius: 20px;
    box-sizing: border-box;
    top: 0;
    left: 0;
    animation: around 0.7s ease-in-out infinite;
  }
  
  .loader::after {
    animation: around 0.7s ease-in-out 0.1s infinite;
    background: transparent;
  }