.address-list-wrapper-cover {
    padding: 10px;
    display: flex;
    justify-content: center;
}

.list-cover-address-box {
    width: 100%;
    max-width: 400px;
}
.add-address-bnt-repo {
    text-align: center;
}
.add-ress-lis-wrap {
    height: 80vh;
    overflow-y: scroll;
}
.add-address-bnt-repo button {
    background: #0CB875 0% 0% no-repeat padding-box;
    border-radius: 8px;
    border: none;
    padding: 8px 20px;
    font-size: 14px;
    font-weight: 500;
    color: white;
}
.address-checkbox {
    display: flex;
    align-items: center;
}

.address-card {
    border: 1px solid #00a34f;
    /* Border color similar to the green in the image */
    border-radius: 10px;
    padding: 10px;
    position: relative;
    background-color: white;
}

.address-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* .address-checkbox input[type="checkbox"] {
    margin-right: 10px;
} */

.address-actions .edit-btn {
    background-color: transparent;
    border: none;
    color: #00a34f;
    cursor: pointer;
    font-size: 13px;
}
label.type-add {
    font-weight: 500;
    font-size: 14px;
}
.address-content {
    margin-top: 10px;
    color: #555;
    font-size: 13px;
}
button.delete-btn-repo svg {
    font-size: 21px;
}
.address-content p {
    margin: 5px 0;
    font-size: 12px;
    font-weight: 500;
    text-transform: capitalize;
}

.address-delete {
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.delete-btn-repo {
    background-color: transparent;
    border: none;
    color: #ff5959;
    cursor: pointer;
}

/* From Uiverse.io by vishnupprajapat */
.checkbox-wrapper-46 input[type="checkbox"] {
    display: none;
    visibility: hidden;
}

.checkbox-wrapper-46 .cbx-repo {
    margin: auto;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
}

.checkbox-wrapper-46 .cbx-repo span {
    display: inline-block;
    vertical-align: middle;
    transform: translate3d(0, 0, 0);
}

.checkbox-wrapper-46 .cbx-repo span:first-child {
    position: relative;
    width: 18px;
    height: 18px;
    border-radius: 3px;
    transform: scale(1);
    vertical-align: middle;
    border: 1px solid #007E4D;
    transition: all 0.2s ease;
}

.checkbox-wrapper-46 .cbx-repo span:first-child svg {
    position: absolute;
    top: 3px;
    left: 2px;
    fill: none;
    stroke: green;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 16px;
    stroke-dashoffset: 16px;
    transition: all 0.3s ease;
    transition-delay: 0.1s;
    transform: translate3d(0, 0, 0);
}


.checkbox-wrapper-46 .cbx-repo span:last-child {
    padding-left: 8px;
}

.checkbox-wrapper-46 .cbx-repo:hover span:first-child {
    border-color: #007E4D;
}

.checkbox-wrapper-46 .inp-cbx-repo:checked+.cbx-repo span:first-child {
    background: #007E4D;
    border-color: #007E4D;
    animation: wave-46 0.4s ease;
}

.checkbox-wrapper-46 {
    margin-right: 8px;
}

.checkbox-wrapper-46 .inp-cbx:checked+.cbx-repo span:first-child svg {
    stroke-dashoffset: 0;
}

.checkbox-wrapper-46 .inp-cbx:checked+.cbx-repo span:first-child:before {
    transform: scale(3.5);
    opacity: 0;
    transition: all 0.6s ease;
}

@keyframes wave-46 {
    50% {
        transform: scale(0.9);
    }
}