.loading {
    font-size: 30px;
    font-family: "Montserrat", sans-serif;
    font-weight: 800;
    text-align: center;
}

.loading span {
    display: inline-block;
    /* margin: 0 -0.05em; */
}

.mediloader-data {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
}

.mediloader-data img {
    animation: loading03 0.7s infinite alternate;
    width: 30%;
}

.loading03 span {
    animation: loading03 0.7s infinite alternate;
    color: #009688;
}

.loading03 span:nth-child(2) {
    animation-delay: 0.1s;
}

.loading03 span:nth-child(3) {
    animation-delay: 0.2s;
}

.loading03 span:nth-child(4) {
    animation-delay: 0.3s;
}

.loading03 span:nth-child(5) {
    animation-delay: 0.4s;
}

.loading03 span:nth-child(6) {
    animation-delay: 0.5s;
}

.loading03 span:nth-child(7) {
    animation-delay: 0.6s;
}

.loading03 span:nth-child(7) {
    animation-delay: 0.7s;
}

@keyframes loading03 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0.8);
    }
}