#left-tabs-example-repo-cont-tab-second {
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #A1A1A129;
    border-radius: 10px;
}

#left-tabs-example-repo-cont-tab-first {
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #A1A1A129;
    border-radius: 10px;
}

.user-name.text-center.text-repoo {
    font-size: 15px !important;
}

/* Container for the message and button */
.empty-order-container {
    text-align: center;
    font-family: 'Arial', sans-serif;
    height: 65vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* Styling the text */
.empty-order-text {
    font-size: 18px;
    color: #333;
    margin-bottom: 30px;
    font-weight: 500;
}

button.empty-order-button svg {
    font-size: 25px;
}

button.empty-order-button img {
    width: 25px;
    filter: invert(1);
    margin-right: 6px;
}

/* Button styling */
.empty-order-button {
    padding: 8px 30px;
    background-color: #198754;
    color: #fff;
    border: none;
    border-radius: 8px;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding-left: 20px;
}

/* Hover effect for the button */
.empty-order-button:hover {
    background-color: #2cd787;
    /* Darker shade on hover */
    transform: scale(1.05);
    /* Slight zoom on hover */
}

/* Optional: add focus effect for accessibility */
.empty-order-button:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(0, 140, 186, 0.5);
    /* Light focus glow */
}


.view-more-less button {
    /* text-align: center; */
    border: none;
    background: none;
    font-size: 16px;
    margin-bottom: 10px;
    color: #0d6efd;
    font-weight: 500;
    text-decoration: underline;
}

button.view-more-btn svg {
    font-size: 20px;
}

.view-more-less {
    text-align: center;
}

#left-tabs-example-repo-cont-tab-third {
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #A1A1A129;
    border-radius: 10px;
}

#left-tabs-example-repo-cont-tab-forth {
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #A1A1A129;
    border-radius: 10px;
}

#left-tabs-example-repo-cont-tab-fifth {
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #A1A1A129;
    border-radius: 10px;
}#left-tabs-example-repo-cont-tab-Six {
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #A1A1A129;
    border-radius: 10px;
}#left-tabs-example-repo-cont-tab-Seven {
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #A1A1A129;
    border-radius: 10px;
}

.order-split-text p {
    font-size: 13px;
    margin-bottom: 8px;
    color: #898989;
}

.tab-cover.order-details-tab {
    margin-top: -25px !important;
}

.order-details-repo-data {
    padding-top: 30px !important;
    padding-bottom: 35px !important;
}

.medicine-order-details-cover {
    margin-top: -5px;
    padding: 10px;
}

.product-length-box p {
    font-size: 10px;
    margin-bottom: 0px;
    margin-top: 2px;
    color: #1F88FE;
    font-weight: 500;
}

.order-cover-repo-wrap-box-con {
    position: relative;
}

.product-length-box {
    position: absolute;
    bottom: 12px;
    left: 10px;
}

.order-box-coverdetails {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    /* box-shadow: 0px 3px 6px #00A26336; */
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 15px;
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    position: relative;
    padding-bottom: 35px;
}

.tele-box-repo {
    padding-bottom: 20px !important;
}

.order-date-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.order-date-details p {
    margin-bottom: 0px;
    font-size: 12px;
    font-weight: 500;
}

.order-date-details span {
    font-size: 10px;
    /* background: #FFFFFF 0% 0% no-repeat padding-box; */
    /* box-shadow: 0px 3px 6px #91919129; */
    border-radius: 3px;
    padding: 2px 5px;
    /* background: #2196F3; */
    color: white;
}

.right-box-cover-details {
    display: flex;
    align-items: center;
    gap: 3px;
    position: absolute;
    right: 10px;
    bottom: 8px;
}

hr.orderhr {
    margin-top: 6px;
    margin-bottom: 6px;
}

.orderDetails-product-box {
    display: flex;
    align-items: end;
    justify-content: space-between;
}

.lab-order-card-lab {
    height: 50px !important;
    overflow: hidden;
}

.more-appointment-box-repo {
    position: absolute;
    bottom: 10px;
}

.more-appointment-box-repo span {
    font-size: 12px;
    color: #0d6efd;
    font-weight: 500;
}

.left-box-cover-details {
    display: flex;
    align-items: center;
}

.right-box-cover-details button {
    background: #00A263 0% 0% no-repeat padding-box;
    border-radius: 5px;
    border: none;
    padding: 5px 6px;
    font-size: 9px;
    color: white;
    font-weight: 500;
}

.reorder-btn-history {
    background: #4FA2FF 0% 0% no-repeat padding-box !important;
    border-radius: 5px;
    border: none;
    padding: 5px 14px !important;
    font-size: 9px;
    color: white;
    font-weight: 500;
}

button.reorder-btn {
    background: #4FA2FF 0% 0% no-repeat padding-box;
    border: 1px solid #FFFFFF;
    border-radius: 5px;
}

.details-box-repo-text p {
    margin-bottom: 1px;
    font-size: 11px;
}

.orderdetails-image-box {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0.5px solid #007E4D;
    border-radius: 6px;
    padding: 5px;
}

.orderdetails-image-box img {
    aspect-ratio: 3/2;
    object-fit: contain;
    width: 50px;
    height: 60px;
}

.left-box-cover-details {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 75%;
}

.details-box-repo-text h5 {
    margin-bottom: 4px;
    font-size: 12px;
    font-weight: 400 !important;
}