.search-result-data-box-cover {
    display: flex;
    padding: 8px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.5px solid #D8D8D8;
    width: 95%;
    margin: 0 auto;
    cursor: pointer;
}

.search-data-box-cont {
    display: flex;
    text-align: start;
    align-items: center;
}

.search-data-box-cont img {
    aspect-ratio: 3/3;
    object-fit: contain;
    width: 30px;
    height: 50px;
}

.search-data-box-cont p {
    margin-bottom: 0px;
    margin-left: 10px;
    font-size: 12px;
    color: #848484;
}

.placeholder-animate::placeholder {
    transition: opacity 0.3s ease;
    opacity: 1;
}

.placeholder-animate:focus::placeholder {
    opacity: 0.5;
}


.data-repo-cover-medi-search {
    margin-top: 15px;
    position: relative;
}

p.no-medicine-found {
    font-size: 20px;
    font-weight: 500;
    position: relative;
}

p.no-medicine-found img {
    width: 45%;
    position: absolute;
    top: 150px;
    left: 30%;
}

.search-left-arrow-box svg {
    color: #9E9E9E;
}