.search-data-input-box {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.image-box-slider-repo {
    cursor: pointer;
}

.search-data-input-box input {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 22px;
    border: none;
    width: 93%;
    padding: 13px;
    font-size: 15px;
    padding-bottom: 10px;
}

.search-data-input-box input::placeholder {
    font-size: 13px;
    text-align: left;
    font: normal normal bold 12px/16px;
    letter-spacing: 0px;
    color: #8B8B8B;
    padding-left: 7px;
}

.product-btn-box-store-repo button {
    background: #0CB875 0% 0% no-repeat padding-box;
    border-radius: 8px;
    border: none;
    padding: 8px 30px;
    color: white;
    font-size: 14px;
    font-weight: 500;
}

.search-data-input-box input:focus {
    outline: none;
}

.prescription-box-data {
    background: transparent linear-gradient(111deg, #FFFFFF 0%, #16FFA4 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 4px #00000029;
    border: 1px solid #FFFFFF;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 15px;
    width: 100%;
    padding-right: 9px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.prescription-image-box-repo-img img {
    width: 35px;
}

.recent-product-data-repo-fixed {
    padding: 10px;
}

.rect-repo-image {
    margin-bottom: 10px;
    margin-top: 2px;
}

.prescription-text-head p {
    margin-bottom: 0px;
    font-size: 13px;
    text-align: left;
    font: normal normal 500 17px/16px Nunito;
    letter-spacing: 0px;
    color: #010000;
}

.image-box-slider-repo-recent img {
    aspect-ratio: 3/4;
    height: 90%;
    width: 80%;
    padding: 10px;
}

.prescription-repo-box-cover {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
    width: 95%;
    gap: 12px;
}

.data-pro-max-repo-prescription {
    display: flex;
    justify-content: center;
}

.doctor-prescription-data {
    background: transparent linear-gradient(111deg, #FFFFFF 0%, #FFD589 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 4px #00000029;
    border: 1px solid #FFFFFF;
    border-radius: 10px;
}

.prescription-image-box-repo-img.doctor-prescription-data-img img {
    width: 26px;
}

.categories-repo-box-data {
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
    padding: 8px;
}

.categories-data p {
    margin-bottom: 0px;
    text-align: left;
    font: normal normal 800 16px/22px Nunito;
    letter-spacing: 0px;
    color: #363636;
}

.product-wrapper-cover-box {
    padding: 15px;
    padding-left: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.box-repo-data-content {
    background: white;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 15px 0px 15px 0px;
    width: 68px;
    box-shadow: 0px 3px 6px #00000029;
    padding: 12px;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.explore-product-repo-box-cover {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.wallet-categories-section-image.no-wallet-found-repo {
    margin-top: 65px;
    flex-direction: column;
}

.wallet-categories-section-image.no-wallet-found-repo h6 {
    font-size: 25px;
    margin-top: 15px;
}

.wallet-categories-section-image.no-wallet-found-repo img {
    margin-bottom: 0px !important;
}

.box-repo-data-content img {
    width: 30px;
    height: 44px;
}

.explore-product-repo-box-wrapper {
    display: flex;
    gap: 8px;
    margin-left: -16px;
}

.explore-product-repo-box-cover p {
    font-size: 13px;
    text-align: center;
    margin-top: 5px;
    font-weight: 500;
    width: 72px;
}

.categories-view-all p {
    margin-bottom: 0px;
    text-align: left;
    font: normal normal bold 14px/18px Nunito;
    letter-spacing: 0px;
    color: #002623;
    text-transform: uppercase;
}

p.pack-box-text {
    font-size: 9px;
    text-align: start !important;
    margin-bottom: 0px;
    font-weight: 500;
    margin-top: 3px;
    margin-left: -3px;
    color: #a5a5a5;
    height: 25px;
}

.corner-data-box {
    margin-bottom: 20px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    padding: 10px;
    height: 94%;
    border-radius: 6px;
    position: relative;
    cursor: pointer;
}

.repo-corner-col {
    text-align: center;
}

.image-box-repo {
    /* box-shadow: 0px 3px 6px #00000029; */
    border-radius: 30px;
    /* width: 65px;
    height: 65px; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.out-of-stock.swiper-repo-text-p p {
    font-size: 15px;
}

.qty-repo-box {
    display: flex;
    align-items: center;
    width: 102% !important;
    justify-content: space-between;
}

.qty-repo-box button {
    background: none;
    border: none;
    font-size: 13px;
    padding: 0px;
}

.qty-repo-box button {
    background: none;
    border: none;
    font-size: 16px;
    padding: 0px;
    color: #CD0000;
    text-decoration: underline;
    margin-top: -5px;
    font-weight: 500;
}

.product-price-repo-store span {
    font: normal normal 800 16px/19px Nunito;
    letter-spacing: 0px;
    color: #1F88FE;
}

.product-price-repo-store p {
    margin-bottom: 0px;
    font-size: 15px;
}

span.item-qty {
    font-size: 12px !important;
    color: #363636 !important;
    font-weight: 500 !important;
}

.product-btn-box-store button {
    background: #0CB875 0% 0% no-repeat padding-box;
    border-radius: 19px;
    border: none;
    padding: 6px 18px;
    color: white;
    font-size: 13px;
}

.coupon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.success-msg {
    font-size: 16px;
    text-align: center;
}

.coupon-box {
    display: flex;
    align-items: center;
    /* background: #f4f4f4; */
    padding: 6px 12px;
    border-radius: 5px;
    gap: 15px;
    border: 1px solid dimgray;
}

.coupon-code {
    font-weight: 500;
    font-size: 16px;
    color: #333;
}

.copy-button {
    /* background: #007bff; */
    color: #4CAF50;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 3px;
    background: none;
}

.Repo-redeem-code {
    background: #08ab08;
    color: white;
    padding: 7px 26px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-size: 18px;
    margin-top: 10px;
    font-weight: 500;
}

.Repo-redeem-code:hover {
    background: darkgreen;
}


.footer-repo-head-boxs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 29px 29px 0px 0px;
    padding: 10px 15px;
    margin-top: 10px;
    padding-bottom: 3px;
}

.ellipsis-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    line-height: 1.2;
    max-height: 2.4em;
    text-align: start;
}

.product-text-head p {
    margin-bottom: 0px;
    font-size: 10px;
    margin-top: 8px;
    text-align: center;
    font-weight: 500;
    padding: 2px;
    padding-top: 0px;
}

.product-text-head {
    width: 75px;
}

.image-box-slider-repo img {
    width: 100%;
    height: 100%;
}

.explore-categories-wrapper {
    background: transparent linear-gradient(116deg, #E2FFEC 0%, #C5F1FF 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    margin-top: 10px;
    padding: 20px;
}

.explore-head-text-box p {
    text-align: left;
    font: normal normal 800 16px/22px Nunito;
    letter-spacing: 0px;
    color: #363636;
}

.image-box-repo img {
    width: 50px;
}

.repo-cate img {
    width: 40px !important;
}

.explore-btn-repo-box button p {
    margin-bottom: 0px;
    font: normal normal bold 12px/16px Nunito;
    letter-spacing: 0px;
    color: #007E4D;
    text-transform: uppercase;
}

.explore-btn-repo-box {
    display: flex;
    justify-content: center;
}

.price-repo-text {
    display: flex;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 2px;
    justify-content: space-between;
}

.price-tag-repo {
    display: flex;
    align-items: center;
}

.offer-text-box p {
    margin-bottom: 0px;
    font: normal normal 800 16px/22px Nunito;
    letter-spacing: 0px;
    color: #530060;
}

.offer-text-box p {
    padding-left: 10px;
    padding-top: 12px;
}

.offer-view-all p {
    margin-bottom: 0px;
    font: normal normal bold 12px/18px Nunito;
    letter-spacing: 0px;
    color: #002623;
    text-transform: uppercase;
}

.add-product-box button {
    /* background: red; */
    padding: 6px 25px;
    background: #0CB875 0% 0% no-repeat padding-box;
    border-radius: 5px;
}

.add-product-box {
    margin-top: 10px;
}

.add-product-box button p {
    font-size: 13px;
    font-weight: 600;
    color: white;
}

.explore-btn-repo-box button {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0.30000001192092896px solid #007E4D;
    border-radius: 13px;
    padding: 5px 16px;
}

.recent-text-repo-boxs {
    margin-top: 0px;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    padding-bottom: 0px;
}

.recent-text-box p {
    font: normal normal 800 16px/22px Nunito;
    letter-spacing: 0px;
    color: #20948B;
    margin-bottom: 0px;
}

.recent-view-all p {
    margin-bottom: 0px;
    font: normal normal bold 12px/18px Nunito;
    letter-spacing: 0px;
    color: #002623;
    text-transform: uppercase;
}

.recent-product-data-repo-cover {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    padding: 10px;
    margin-right: 10px;
    margin-left: 5px;
    margin-bottom: 15px;
    cursor: pointer;
    position: relative;
}

.shop-head-text {
    padding: 10px;
}

.shop-head-text h6 {
    font: normal normal 800 16px/22px Nunito;
    letter-spacing: 0px;
    color: #363636;
    margin-bottom: 0px;
}

.error {
    color: red;
    font-size: 12px;
    margin-top: 5px;
}

.text-help-box button {
    background: none;
    border: none;
    padding: 0px;
    color: #1F88FE;
    font-size: 13px;
    font-weight: 600;
}

.image-cover-recent img {
    aspect-ratio: 3/2;
    object-fit: contain;
    width: 100%;
}

.price-repo-text {
    display: flex;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 2px;
}

.add-cart-repo-boxs {
    text-align: center;
}

.recent-ellipsis-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    line-height: 1.2;
    max-height: 2.4em;
    text-align: start;
    width: 115px;
    font-size: 11px !important;
    height: 100px;
    margin-bottom: 0px;
    margin-left: -3px !important;
}

.line-repo {
    width: 80px;
    background: #B8B8B8;
    height: 1px;
    margin-bottom: 3px;
}

.qty-repo-box p {
    letter-spacing: 0px;
    color: #006D43;
    font-size: 12px;
    font-weight: 500;
}

.price-repo-text p {
    letter-spacing: 0px;
    color: #1F88FE;
    font-size: 14px;
    font-weight: 600;
}

.offer-recent-text-box span {
    font-size: 11px;
    color: #FFA502;
}

.offer-recent-text-box p {
    margin-bottom: 0px;
    color: #0CB875;
    position: absolute;
    top: 0px;
    right: 0px;
    background: #0CB875;
    color: white;
    padding: 4px;
    font-size: 9px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.offer-recent-text-box span svg {
    margin-top: -3px;
    margin-right: 2px;
}

.product-box-cover {
    cursor: pointer;
}

/* .category-content-data-box {
    margin-top: 10px;
    margin-left: 8px;
} */

.price-tag-repo span {
    font-size: 11px;
    margin-left: 6px;
    color: grey;
    text-decoration: line-through;
    /* margin-top: 3px; */
}

.price-star span svg {
    margin-top: -4px;
    margin-right: 2px;
}

.price-star span {
    color: #ffb125 !important;
    font-size: 12px;
}

.image-cover-recent {
    margin-bottom: 5px;
    text-align: center;
    overflow: hidden;
}

.offer-recent-text-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.qty-repo-box {
    width: fit-content;
}

.shop-head-text {
    padding: 10px;
    padding-bottom: 0px;
}

.shop-image-box-slider-repo p {
    margin-left: 13px;
    width: 45px;
    color: white;
    font-size: 13px;
}

.shop-image-box-slider-repo {
    background-size: contain;
    background-repeat: no-repeat;
    width: 130px;
    height: 65px;
    display: flex;
    align-items: center;
    cursor: pointer;
}