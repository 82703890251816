.loader3 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.circle1 {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin: 0 5px;
    background-color: #009688;
    animation: circle1 1s ease-in-out infinite;
}
/* .start{
    justify-content: flex-start !important;
} */
.circle1:nth-child(2) {
    animation-delay: 0.2s;
}

.circle1:nth-child(3) {
    animation-delay: 0.4s;
}

.circle1:nth-child(4) {
    animation-delay: 0.6s;
}

@keyframes circle1 {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1.5);
        opacity: 0.5;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}