.product-swiper-image img {
    aspect-ratio: 3/2;
    object-fit: contain;
    height: 180px;
}
.repo-single-offer-repo {
    margin-left: -25px !important;
}
.product-swiper {
    margin-top: 10px;
}
.delivery-date-repo p {
    font-size: 13px;
    margin-bottom: 0px;
    font-weight: 500;
}
.prod-con-desc-para-repo p h3 {
    font-size: 14px;
}
.product-varient-repo-box h6 {
    color: black;
}
.repo-tepo-wrap{
    justify-content: center !important;
}
.data-repo-variant-texts p {
    margin-bottom: 0px;
    padding: 4px 9px;
    border-radius: 5px;
    font-size: 11px;
    cursor: pointer;
    padding-bottom: 2px;
}
.repo-desc-wrapper-boxss {
    height: 90vh;
    overflow-y: scroll;
}
.marketer-repo-text{
    margin-top: 8px !important;
}
.heade-marketer-text p {
    font: normal normal bold 15px/19px Nunito;
    letter-spacing: 0px;
    color: #007E4D;
    margin-bottom: 8px;
}
.similar-product-data-repo {
    margin-left: -15px;
}
.manufacturer-data p {
    margin-bottom: 0px;
    font: normal normal 400 13px/16px Nunito;
    letter-spacing: 0px;
    color: #989898;
}
.marketer-repo-te p {
    margin-bottom: 0px;
    font: normal normal 400 13px/16px Nunito;
    letter-spacing: 0px;
    color: black;
}
.head-offer-text h6 {
    /* margin-left: 0px !important; */
    font: normal normal 600 18px/22px Nunito;
    letter-spacing: 0px;
    color: #20948B;
    margin-bottom: 0px;
}
.product-swiper-image {
    padding-bottom: 45px;
}
.social-cue p {
    margin-bottom: 3px;
    font: normal 500 11px/18px Nunito;
    letter-spacing: 0px;
    color: #007C72;
}
.out-of-stock.left-repo-size p {
    font-size: 18px;
}
.product-container-wrapper {
    padding-left: 15px;
    margin-top: 0px;
}
.social-cue {
    padding-left: 12px;
    margin-top: 10px;
}
.data-repo-variant-options-select select {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #6B6B6B29;
    border-radius: 8px;
    padding: 8px 10px;
    font-size: 15px;
    border: none;
    width: 49%;
    margin-left: -5px;
}
.data-repo-variant-options-select {
    display: flex;
    gap: 10px;
}
.both-repo-text {
    margin-top: 8px;
}
.product-btn-repo-data-description {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.pack-size p {
    font-size: 11px;
    margin-top: 3px;
    font-weight: 500;
    color: #aeaeae;
    margin-bottom: 0px;
}
.product-pric-repo-text-con {
    margin-top: 0px;
}
.product-pric-repo-text-con span{
    color: gray;
    text-decoration: line-through;
    margin-left: 3px;
}
.data-repo-variant-options-select select:focus-visible{
    border: 1px solid #00b77a;
    outline: 1px solid #00b77a;
}
.product-expiry p {
    font-size: 11px;
    margin-bottom: 0px;
    color: gray;
}
.swiper-pagination-bullet {
    background: #fff;
    border: 2px solid #00b77a;
}
.product-ratings p {
    margin-bottom: -3px;
    font-size: 14px !important;
    font: normal normal 800 15px/22px Nunito;
    letter-spacing: 0px;
    color: #007E4D;
}
.prod-con-desc-para-repo strong {
    color: #007e51;
    font-size: 13px;
}
.prod-con-desc-para-repo p {
    font-size: 12px;
}
.prod-con-desc-para-repo {
    height: 100px;
    overflow: hidden;
}
.prod-head-text-con-single h6 {
    font: normal normal bold 16px/19px Nunito;
    letter-spacing: 0px;
    color: #007E4D;
    margin-bottom: 5px;
}
.prod-head-text-con-single {
    border-bottom: 1px solid #D9D9D9;
    margin-bottom: 10px;
}
.discount-count-text p {
    margin-left: 10px;
    font-size: 12px !important;
    text-transform: uppercase;
    letter-spacing: 0px;
    color: #0CB875;
    margin-top: 3px;
}
.single-product-repo-description {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 12px;
    padding: 10px;
    width: 100%;
    margin-top: 15px;
    margin-left: -5px;
    margin-bottom: 15px;
}
hr.repo-hr-line-data {
    background: #20948B;
    height: 3px;
    width: 98%;
    margin-bottom: 0px;
}
.product-ratings {
    display: flex;
    align-items: center;
    justify-content: end;
    padding-right: 5px;
}
/* .single-repo-desc-repo {
    justify-content: flex-start !important;
} */
.product-pric-repo-text-con p {
    font-size: 16px;
}
.product-pric-repo-text-con span {
    font-size: 11px;
    margin-top: 3px;
}
.similar-head-repo-text h6 {
    font: normal normal 600 16px/22px Nunito;
    letter-spacing: 0px;
    color: #20948B;
    margin-bottom: 0px;
}
.product-name-repo-text p {
    font: normal normal 500 14px/20px Nunito;
    letter-spacing: 0px;
    color: #002522;
    margin-bottom: 0px;
}
.product-expiry {
    margin-bottom: 5px;
}
.increase-cart-repo-boxs.single-repo-desc-repo {
    margin-left: 10px;
}
.show-btns-repo-con p {
    margin-bottom: 0px;
    margin-top: 10px;
    font: normal normal bold 14px/16px Nunito;
    letter-spacing: 0px;
    color: #1F88FE;
    cursor: pointer;
}
.single-qty-repo-text p {
    margin-bottom: 0px;
    font: normal normal bold 13px/18px Nunito;
    letter-spacing: 0px;
    color: #0CB875;
    padding-right: 10px;
}
.both-repo-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.single-btn-repo-box {
    padding: 6px 45px !important;
}

.product-btn-repo-data-description {
    padding-top: 6px;
    padding-bottom: 10px;
}

.single-repo-desc {
    text-align: start !important;
}

.data-repo-variant-texts p {
    margin-bottom: 0px;
    padding: 4px 9px;
    border-radius: 5px;
    font-size: 11px;
    cursor: pointer;
    padding-bottom: 2px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.data-repo-variant-texts {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.product-varient-repo-box {
    margin-top: 10px;
}

.px-data-rpeo {
    padding-left: 10px;
}

.px-data-rpeo img {
    width: 20px;
}

.px-data-rpeo span {
    font-size: 13px;
    margin-left: 5px;
    color: #CD0000;
}