.images-box {
    /* background-size: cover; */
    width: 100%;
    /* height: 100%; */
    /* background-repeat: no-repeat; */
    display: fali;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.right-header-data {
    display: flex;
    align-items: center;
}

.search-icon-repo svg {
    font-size: 25px;
    color: #198754;
    margin-top: 3px;
}

.search-icon-repo {
    cursor: pointer;
}

p.count-cart-text {
    background: #ffb125;
    width: 20px;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    height: 20px;
    font-size: 10px;
    padding-left: 2px;
    font-weight: 600;
    padding-top: 3px;
    position: relative;
    left: -10px;
    color: white;
}

.cart-icon-repo {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    left: 10px;
}

.header-box-repo-data {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 8px;
    padding-right: 15px;
}

.data-box-repo-store button svg {
    color: grey;
    font-size: 19px;
}

.data-box-repo-store button {
    background: none;
    border: none;
}

.data-box-repo-store {
    display: flex;
    /* align-items: center; */
}

.header-title h6 {
    margin-bottom: 0px;
    margin-left: 5px;
    font-size: 16px;
}

.cart-icon-repo img {
    width: 32px;
}

.input-image-data img {
    position: absolute;
    right: 25px !important;
    top: 50%;
    transform: translateY(-50%);
}
.header-top-section-category-section-locat button {
    display: flex;
    align-items: center;
    border: none;
    background: none;
    gap: 3px;
}
.header-top-section-category-section-locat h5 {
    font-size: 13px;
    margin-bottom: 0px;
    color: #00796B;
}
.input-image-data img {
    width: 4% !important;
    margin-top: -48px !important;
}