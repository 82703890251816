.category-content-data-box {
    height: 100vh;
    overflow-y: auto;
    padding: 7px;
    box-sizing: border-box;
    width: 75px;
}

.category-content-data-box::-webkit-scrollbar-track {
    background: #CBCBCB !important;
}
.repo-col-repo {
    padding-left: 0px !important;
}
.product-box-wrap-cover-div {
    height: 100vh;
    overflow-y: auto;
}
.category-con-wrapper-box {
    padding-left: 0px;
}
.category-repo-data-box {
    margin-bottom: 8px;
    padding: 10px;
    height: 94%;
    padding-left: 8px;
    cursor: pointer;
    padding-bottom: 5px;
}
.data-box-wrap-cate.active {
    background: #E6FCFF 0% 0% no-repeat padding-box;
    border-right: 2px solid #0CB875;
    padding-top: 6px;
    padding-bottom: 2px;
}
.category-repo-corner-col {
    width: 50%;
    border-left: 1px solid #e2e2e2;
    border-bottom: 1px solid #e2e2e2;
}
.out-of-stock p {
    margin-bottom: 0px;
    color: red;
    font-weight: 500;
    font-size: 15px;
    padding-bottom: 7px;
}
.corner-data-container {
    display: flex;
    flex-wrap: wrap;
}
/* .category-content-data-box::-webkit-scrollbar-thumb {
    background: #0CB875;
    border-radius: 10px;
    height: 10px !important;
}

.category-content-data-box::-webkit-scrollbar {
    width: 2px !important;
    height: 10px !important;
} */
.add-product-box button {
    /* background: red; */
    padding: 5px 20px;
    background: #0CB875 0% 0% no-repeat padding-box;
    border-radius: 5px;
}
.data-box-wrap-cate {
    margin-bottom: 10px;
    cursor: pointer;
}

.category-content-data-box {
    padding-top: 5px;
    margin-left: 0px;
    margin-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
}
.border-red {
    border: 1px solid red !important;
}