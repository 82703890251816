.hospital-card-repo {
    display: flex;
    /* align-items: center; */
    /* justify-content: space-between; */
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 5px;
    border-radius: 5px;
    padding-left: 15px;
    /* gap: 15px; */
}

.hospital-cover-repo-box {
    display: flex;
    /* grid-template-columns: repeat(1, 1fr); */
    gap: 15px;
    flex-direction: column;
}
.hospital-card-image-loki img {
    width: 40px;
}
.icons-repo-cover-de.depart-icon {
    border-radius: 50%;
    background-color: white !important;
    width: 60px !important;
    height: 60px !important;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.icon-path-rpeo img {
    width: 50px;
}
.repo-data-bima{
    padding-top: 0px !important;
}
.icons-repo-cover-de.depart-icon svg {
    font-size: 22px;
}

.department-clover-repo-hos {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0px;
    margin-top: 10px;
}

.faq-box-clover {
    width: 100% !important;
}

.faq-box-clover .accordion-item {
    border-radius: 5px !important;
}

.text-department-repo p {
    font-size: 11px;
    font-weight: 500;
}

.department-card-clover {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.hospital-text-head button {
    background: none;
    border: none;
    font-size: 13px;
    text-decoration: underline;
}


.hospital-text-head {
    margin-bottom: 10px;
}

.hospital-text-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.hospital-text-head p {
    margin-bottom: 0px;
    font-size: 15px;
    font-weight: 500;
}

.ratings-hospital p {
    margin-bottom: 0px;
    font-size: 12px;
    position: absolute;
    top: 0px;
    right: 0px;
    background: #e5e5e5;
    padding: 3px;
    padding-left: 6px;
    padding-right: 6px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    padding-bottom: 0px;
}

.hospital-card-repo {
    padding: 10px;
    transition: transform 0.3s;
    /* border: 1px solid #ddd; */
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    background-color: #fff;
    position: relative;
    cursor: pointer;
    flex-direction: column;
    padding-left: 15px;
    padding-right: 15px;
    background: transparent linear-gradient(122deg, #F6FDFF 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #88888859;
    border-radius: 10px;
}

.hospital-tepo-image {
    padding: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    width: 41%;
    min-height: 70px;
    justify-content: center;
}

.hospital-tepo-image img {
    width: 70%;
}

.banner-image-repo-clover img {
    width: 100%;
}

.hospital-wrapper-box {
    padding: 7px;
}

/* .hospital-card-repo img {
    width: 35%;
    filter: drop-shadow(2px 0px 2px grey);
} */
.hospital-card-image-loki p {
    margin-bottom: 0px;
    font-size: 13px;
    font-weight: 500;
}
.image-hospital {
    width: fit-content;
}

.ratings-hospital p {
    margin-bottom: 0px;
    font-size: 12px;
}

.ratings-hospital p span {
    font-weight: 500;
    color: #4b4b4b;
}

.hospital-data-wrap p {
    font-size: 11px;
    margin-bottom: 0px;
}

.hospital-card-repo:hover {
    transform: scale(1.05);
    /* Slightly enlarge on hover */
}

.hospital-data-wrap p span {
    font-weight: 500;
    text-transform: capitalize;
}
p.no-medicine-found-bankai {
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    font-weight: 500;
    flex-direction: column;
}
span.click-bankai-span {
    color: #0d6efd;
    cursor: pointer;
    text-decoration: underline;
    text-align: center;
}
.ratings-hospital p svg {
    color: yellow;
    margin-top: -4px;
}

.star-ratings {
    top: -1px;
    left: 3px;
}

@keyframes rotating-shadow {
    0% {
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    }
    25% {
        box-shadow: 4px 2px 4px rgba(0, 0, 0, 0.3);
    }
    50% {
        box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.3);
    }
    75% {
        box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.3);
    }
    100% {
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    }
}

.category-data-cover {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    padding: 0px;
    height: 100px;
    text-align: center;
    transition: transform 0.3s;
    /* padding-top: 10px; */
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    gap: 6px;
    cursor: pointer;
    justify-content: space-evenly;
}

.icons-repo-cover-de {
    /* background: red;
    width: 40px;
    height: 40px; */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}
.icons-repo-cover-de img {
    border-radius: 10px;
    width: 45px;
}
.category-data-cover:hover {
    transform: scale(1.05);
    /* Slightly enlarge on hover */
}

.category-data-cover svg {
    color: white;
    font-size: 22px;
}

.category-text {
    margin-top: 10px;
    /* font-size: 1.1rem; */
    color: #333;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}





.tab-navigation {
    display: flex;
    gap: 10px;
    margin-top: 20px;
    padding: 10px;
}

.tab-navigation button {
    padding: 8px 16px;
    background-color: white;
    border: none;
    cursor: pointer;
    font-size: 13px;
    border-radius: 8px;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.unique-direction-icon {
    color: #007bff;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 14px;
    background: transparent;
    font-weight: 500;
    text-decoration: underline;
    margin-top: 8px;
}
.hospital-card-image-repo .dot {
    width: 8px; /* Adjust dot size as needed */
    height: 8px;
    background-color: #00b662; /* Adjust color as needed */
    border-radius: 50%;
    position: absolute;
    top: 3px;
    right: 3px;
    /* transform: translate(-50%, -50%); */
    /* animation: orbit 4s linear infinite;  */
}

@keyframes orbit {
    0% {
        transform: translate(-50%, -50%) rotate(0deg) translate(24px); /* 24px sets radius of orbit */
    }
    100% {
        transform: translate(-50%, -50%) rotate(360deg) translate(24px);
    }
}
.tab-navigation .active-tab {
    background-color: #0CB875;
    color: white;
}
.about-text {
    max-height: 52px; /* Adjust initial height */
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
    margin-bottom: 0px !important;
}

.expanded {
    max-height: 500px; /* Adjust based on expected content */
}

.read-more-btn {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    /* margin-top: 5px; */
    font-size: 13px;
    font-weight: 500;
}

.about-repo-control {
    margin-top: 0px;
    padding: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    width: 95%;
    margin: 0 auto;
    margin-top: 10px;
    border-radius: 10px;
}
.about-repo-control h5 {
    font-size: 16px;
}
.about-repo-control p {
    font-size: 12px;
    color: black;
    margin-bottom: 8px;
}
