.symptoms-preview-icon-box.repol-clover-repo {
    background: white;
    padding: 5px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}
.repo-card-order-clover{
    padding-bottom: 8px !important;
}
.symptoms-preview-icon-box.repol-clover-repo svg {
    color: #198754;
}
.repo-clover-details-clover-card {
    display: flex;
    justify-content: space-between;
}
.dates-appoint-repo {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.repo-clover-timing-clover {
    border-top: 1px solid #c8c8c8;
    padding-top: 5px;
}
.date-apoint-loki p {
    color: #007e4d;
    font-weight: 500;
    font-size: 13px;
    margin-bottom: 0px;
}
.date-apoint-loki span {
    font-size: 13px;
}
.repo-image-cclover {
    display: flex;
    align-items: center;
    margin-top: 5px;
    gap: 10px;
}
.repo-image-cclover img {
    width: 40px;
}
.repo-image-cclover p {
    margin-bottom: 0px;
    font-size: 13px;
    font-weight: 400;
}
