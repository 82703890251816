.repo-image-diabetes img {
    width: 95%;
    margin: 0 auto;
    margin-top: 15px;
    display: flex;
}

.line-repo-common {
    border: 1px dashed #20948B;
    width: 90px;
    margin-top: -5px;
}

.our-partner-repo-common {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    gap: 5px;
}

.wrapper-clover-repo-task {
    padding: 10px;
}

.wrapper-wellness-loki-para p {
    margin-bottom: 0px;
    padding-bottom: 0px;
    font-size: 14px;
    margin-top: 20px;
}

.repo-title-loki-book {
    text-align: center;
    border-top: 1px solid #b3b3b3;
    margin-top: 15px;
}

.repo-title-loki-book h6 {
    font-size: 17px;
    margin-bottom: 15px;
    padding-top: 15px;
}

.repo-partner-cover-boxes-bankai {
    padding: 10px;
}

.content-repo-ven-para p {
    font-size: 13px;
}

.wrapper-package-vendor-loki {
    border: 1px solid #198754;
    border-radius: 8px;
    padding: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    margin-bottom: 15px;
    cursor: pointer;
}

.price-coll span {
    text-decoration: line-through;
    color: #9a9a9a;
    font-weight: 600;
    font-size: 14px;
}

.wrapper-cover-ven-title {
    border-bottom: 1px solid #9a9a9a;
    margin-bottom: 5px;
}

.wrapper-cover-ven-title h5 {
    font-size: 14px;
    margin-bottom: 5px;
}

.price-coll {
    display: flex;
    align-items: center;
    gap: 10px;
}

.price-ven-repo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 5px;
}

.price-coll p {
    margin-bottom: 0px;
    font-weight: 600;
    color: #0d6efd;
}

.ellipsis-text-eye {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    line-height: 1.2;
    max-height: 3.4em;
    text-align: start;
}

.wrapper-wellness-loki-buck {
    position: relative;
    max-width: 500px;
    /* Adjust as needed */
    margin: auto;
}
.wrapper-repo-buck {
    padding: 10px;
}
.description-text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    transition: all 0.4s ease-in-out;
    margin-bottom: 0px;
    font-size: 14px;
}

.collapsed {
    -webkit-line-clamp: 3;
    /* Show only 3 lines */
    max-height: 4.5em;
    /* Approx height for 3 lines */
}

.expanded {
    -webkit-line-clamp: unset;
    /* Show full text */
    max-height: 100vh;
}

.read-more-btn-buck {
    background: none;
    border: none;
    color: #0d6efd;
    cursor: pointer;
    font-weight: bold;
    margin-top: 0px;
    transition: opacity 0.3s;
    font-size: 13px;
}
.data-buck-text-ben ul li {
    font-size: 14px;
}
.tit-repo-core p {
    margin-bottom: 5px;
    font-size: 17px;
    font-weight: 500;
}
.repo-price-buck {
    display: flex;
    align-items: center;
    gap: 10px;
}
.repo-price-buck span {
    text-decoration: line-through;
    font-size: 13px;
    color: dimgray;
}
.repo-price-buck p {
    margin-bottom: 0px;
    font-weight: 500;
    color: #0d6efd;
    font-size: 17px;
}
.repo-buck-footer.sticky-bottom button {
    background: #198754;
    border: none;
    font-size: 14px;
    padding: 6px 15px;
    border-radius: 5px;
    color: white;
}
.repo-price-buck p {
    margin-bottom: 0px;
}
.repo-buck-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin-top: 110px;
    border-top: 1px solid #9a9a9a;
    background: white;
}
.repo-pack-title-buck h6 {
    margin-top: 10px;
    color: #198754;
    text-decoration: underline;
}
.read-more-btn:hover {
    opacity: 0.7;
}

.price-ven-repo button {
    background: white;
    font-size: 10px;
    padding: 5px 10px;
    border-radius: 20px;
    font-weight: 500;
    border: 2px solid #198754;
    margin-top: 5px;
}

.cover-boxes-partner-solo {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.solo-image-box img {
    width: 120px;
    margin-bottom: 10px;
}

.solo-content-repo {
    margin-top: 10px;
}

.solo-content-repo h6 {
    margin-bottom: 2px;
    letter-spacing: 0.03px;
    color: #00684B;
}

.solo-content-repo p {
    font-size: 13px;
    margin-bottom: 0px;
}

.solo-image-box {
    text-align: center;
    border-bottom: 1px solid #A2A2A2;
}