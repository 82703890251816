.image-data-box {
    display: flex;
    justify-content: center;
}

.corner-wrapper-box {
    padding: 10px;
    padding-top: 20px;
    height: 80vh;
    overflow-y: scroll;
}

.price-repo-text p {
    margin-bottom: 0px;
}

.price-repo-text {
    display: flex;
    align-items: center;
    margin-top: 3px;
    margin-bottom: 2px;
}

.repo-corner-col {
    padding-left: 8px !important;
    padding-right: 8px !important;
}

.image-data-box img {
    width: 70px;
    height: 70px;
}

.offer-recent-text-box p {
    font-size: 11px;
}

.corner-data-box {
    margin-bottom: 20px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    padding: 10px;
    height: 94%;
    border-radius: 6px;
}

.add-product-box button p {
    margin-bottom: 0px;
}

.add-product-box button {
    border: none;
}

.qty-repo-box p {
    letter-spacing: 0px;
    color: #006D43;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 6px;
}

.add-product-box {
    margin-top: 0px !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.increase-cart-repo-boxs {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}
.px-cover-repo {
    position: absolute;
    left: 8px;
    top: 3px;
}
.px-cover-repo img {
    width: 20px;
}
button.incre-btn-reop {
    background: white !important;
    border: 1px solid #0cb875;
    color: black;
    width: 23px;
    height: 23px;
    border-radius: 50% !important;
    padding: 0px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
button.incre-btn-reop p {
    color: black !important;
    font-size: 14px;
    margin-bottom: 0px;
    margin-left: 0px;
    font-weight: 500;
}
.text-corner {
    width: 100%;
    /* Adjust as needed */
    overflow: hidden;
    /* Hide overflow text */
}

.text-corner p {
    margin-bottom: 0;
    margin-top: 10px;
    font-size: 13px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 35px;
}