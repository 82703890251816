.details-box-repo-text.lab-test-text-order p {
    font-size: 12px !important;
    font-weight: 500 !important;
}
.lab-book-text{
    font-size: 13px !important;
    font-weight: 500 !important;
}
.lab-booked-order-repo-text {
    padding-top: 10px;
    padding-bottom: 0px;
}
.details-box-repo-text.lab-test-text-order {
    margin-bottom: 10px;
}
.test-data-content-box {
    display: flex;
    align-items: center;
    margin-top: 10px;
    gap: 10px;
}
.lab-refund-data{
    width: 100% !important;
    margin-top: 5px !important;
    margin-bottom: 10px !important;
}
.test-data-content-box img {
    width: 28px;
}
.test-name-data-content p {
    margin-bottom: 0px;
    font: normal normal 500 11px/15px Nunito;
    letter-spacing: 0px;
    color: #363636;
}
.test-name-data-content span {
    font: normal normal 500 13px/18px Nunito;
    letter-spacing: 0px;
    color: #363636;
}
.booked-wrapper-repo-cover-box {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 7px;
    padding: 12px 15px;
    margin-bottom: 15px;
}
.patient-details-age-wrapper {
    display: flex;
    justify-content: space-between;
    width: 85%;
    flex-wrap: wrap;
}
.appoint-date-repo-wrapper {
    display: flex;
    justify-content: space-between;
}
.appoint-date-repo-wrapper p {
    margin-bottom: 3px;
}
.appoint-date-repo-wrapper p {
    margin-bottom: 3px;
    font: normal normal 500 14px/18px Nunito;
    letter-spacing: 0px;
    color: #007E4D;
}
.bond-text-repo{
    color: black !important;
    font-size: 13px !important;

}
.report-data-repo-class p {
    margin-bottom: 0px;
    font-size: 13px;
    font-weight: 500;
}
.report-repo-wrapper-cover {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.report-data-content-box.open-box-repo-box img {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}
p.ellipsis-text.repo-text-repo-elli {
    font-size: 12px !important;
    min-height: 15px !important;
    -webkit-line-clamp: 1 !important;
    width: 180px !important;
}
.repor-head-text p {
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 14px;
    font: normal normal 500 14px / 18px Nunito;
    letter-spacing: 0px;
    color: #007E4D;
}
.report-btn-cover-repo-btuton a {
    text-decoration: none;
    font-size: 11px;
    background: #0CB875 0% 0% no-repeat padding-box;
    border-radius: 6px;
    color: white !important;
    padding: 6px 12px;
}
.report-data-repo-class {
    display: flex;
    align-items: center;
    gap: 8px;
}
.lab-date-repo{
    padding-left: 0px !important;
}
.patient-details-age-wrapper p {
    margin-bottom: 0px;
}
.patient-details-wrapper-oreder p {
    margin-bottom: 5px;
}
.lab-order-details-wrap{
    padding: 4px;
}
.lab-order-wrap-details{
    padding: 6px;
}
.lab-booked-order-repo-text p {
    font-size: 14px;
    margin-bottom: 5px;
    letter-spacing: 0px;
    color: #434343;
}

p.booked-text svg {
    color: #21c021;
    font-size: 22px;
}
p.booked-text {
    font-size: 16px;
    font-weight: 500;
    color: #0CB875;
}
hr.lab-data-hr {
    width: 94%;
    margin: 0 auto;
    margin-top: 15px !important;
    margin-bottom: 5px !important;
}
.test-book-test-p {
    font: normal normal 500 16px/22px Nunito;
    letter-spacing: 0px;
    color: #434343;
    margin-top: 12px;
    margin-bottom: 8px;
}
hr.patient-lab-data-hr {
    width: 96%;
    margin: 0 auto;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}
.patien-text-de {
    font: normal normal 800 16px/18px Nunito;
    letter-spacing: 0px;
    color: #007E4D;
    margin-bottom: 16px;
}
.patient-details-wrapper-oreder p {
    margin-bottom: 5px;
    font-size: 13px;
    text-transform: capitalize;
}
