.wrapper-repo-recent-clover {
    display: flex;
}
.wrapper-clover-recent-boc {
    padding: 10px;
    transition: transform 0.3s;
    /* border: 1px solid #ddd; */
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    background-color: #fff;
    position: relative;
    cursor: pointer;
    flex-direction: column;
    /* height: 145px; */
}
.hospital-card-repoo {
    display: flex;
    align-items: flex-start;
    gap: 10px;
}
.button-visit-repo {
    margin-top: 10px;
}
.button-visit-repo button {
    background: #198754;
    padding: 5px 25px;
    border-radius: 5px;
    /* position: absolute;
    left: 50px;
    bottom: 15px; */
}
.button-visit-repo button p {
    margin-bottom: 0px;
    font-size: 12px;
    color: white;
}
