.doc-card {
    width: 100%;
    max-width: 350px;
    background-color: #ffffff;
    border-radius: 12px;
    padding: 10px;
    text-align: center;
    overflow: hidden;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    cursor: pointer;
    padding-bottom: 15px;
}

.doc-profile-image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    /* background-color: #e0e0e0; */
    display: inline-block;
    margin-bottom: 15px;
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px; */
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.doc-doctor-name {
    font-size: 14px;
    font-weight: bold;
    color: #333;
    margin-bottom: 0px;
    border-bottom: 1px solid #b9efe0;
    padding-bottom: 5px;
}

.doc-specialization {
    background-color: #e0f7f5;
    color: black;
    padding: 6px;
    border-radius: 15px;
    font-size: 11px;
    display: inline-block;
    margin-bottom: 5px;
    margin-top: 8px;
    font-weight: 500;
    width: 230px;
}

.doc-qualification, .doc-location {
    font-size: 12px;
    color: black;
    margin-bottom: 0px;
}

.doc-location {
    font-size: 12px;
    color: black;
    margin-bottom: 5px;
}

.doc-appointment-options {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    /* justify-content: center; */
}
.doc-qualification {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* width: 200px; */
    /* text-align: center; */
    display: flex;
    justify-content: center;
    /* align-items: center; */
}

.doc-option {
    background-color: #f8f9fb;
    border-radius: 5px;
    padding: 5px 7px;
    /* width: 100%; */
    text-align: center;
    font-size: 11px;
    color: #333;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05); */
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    gap: 10px;
}

/* .doc-option:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
} */

.doc-price {
    font-weight: bold;
    color: #198754;
    margin-top: 2px;
    font-size: 12px;
}

.doc-book-button {
    background-color: #198754 !important;
    color: #fff;
    font-size: 11px;
    font-weight: bold;
    border: none;
    border-radius: 8px;
    padding: 8px 25px !important;
    /* width: 100%; */
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-transform: uppercase;
}

.doc-book-button:hover {
    background-color: #00796b;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 480px) {
    .doc-doctor-name {
        font-size: 18px;
    }
    .doc-specialization {
        font-size: 13px;
        padding: 4px 12px;
    }
    .doc-qualification, .doc-location {
        font-size: 13px;
    }
    .doc-price {
        font-size: 15px;
    }
    .doc-option {
        padding: 12px;
        font-size: 13px;
    }
    .doc-book-button {
        padding: 10px;
        font-size: 15px;
    }
}