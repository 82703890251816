.shipment-order-wrapper {
    padding: 10px;
    margin-top: 10px;
}

.ship-cart-data {
    padding-left: 0px !important;
    margin-top: 15px !important;
}
.reason-container p {
    font-size: 13px;
    color: black;
}
.reason-container span {
    font-size: 12px;
    color: red;
    font-weight: 400;
}
.order-shipment-data p {
    margin-bottom: 0px;
    font: normal normal 400 12px/19px Nunito;
    letter-spacing: 0px;
    color: #6F6F6F;
}
.refund-data-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFF7CD 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 3px #7373731C;
    border: 1px solid #FFB125;
    border-radius: 10px;
    padding: 10px;
    width: 95%;
    margin: 0 auto;
    margin-top: 15px;
}
.redund-content-box p {
    margin-bottom: 0px;
    font-size: 12px;
    font-weight: 500;
}
.refund-box-process {
    box-shadow: 0px 3px 3px #7373731C;
    border: 1px solid #FFB125;
    border-radius: 10px;
}
.fund-process-box {
    display: flex;
    align-items: center;
    padding: 10px;
    gap: 10px;
}
.fund-process-box p {
    margin-bottom: 0px;
    font-weight: 500;
}
.fund-source span {
    font-size: 12px;
    letter-spacing: 0px;
    color: #8D8D8D;
    margin-bottom: 0px;
}
.fund-source {
    padding: 12px;
    padding-top: 5px;
    padding-bottom: 6px;
}
.md-func-source-box {
    display: flex;
    justify-content: space-between;
    padding: 0px 12px;
}
.md-func-source-box p {
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 14px;
}
.download-invoice button{
    background: #0CB875 0% 0% no-repeat padding-box !important;
}
.fund-total-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFF7CD 0% 0% no-repeat padding-box;
}
.fund-total-box p {
    margin-bottom: 8px;
    color: #007E4D;
    font-weight: 500;
    padding-top: 10px;
    padding-bottom: 2px;
    margin-left: 12px;
    margin-right: 12px;
    font-size: 14px;
}
.redund-content-box {
    display: flex;
    align-items: center;
    gap: 5px;
}
.refund-btn button {
    background: none;
    border: none;
    font-size: 12px;
    text-decoration: underline;
    font-weight: 500;
}
.repo-ship {
    aspect-ratio: 3/2;
    object-fit: contain;
    width: 35px !important;
    height: 50px !important;
}
.reorder-btn-data-repo.sticky-bottom {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 3px 3px 10px #4A4A4A5E;
    /* display: flex; */
    text-align: center;
    padding: 15px;
}
.reorder-btn-data-repo.sticky-bottom button {
    border: none;
    background: #4FA2FF 0% 0% no-repeat padding-box;
    border-radius: 6px;
    padding: 5px 40px;
    font-size: 14px;
    font-weight: 500;
    color: white;
}
.prescipion-image-box-repo-data img {
    width: 55px;
    height: 55px;
    aspect-ratio: 3/2;
    object-fit: contain;
    border-radius: 5px;
}
.prescipion-image-box-repo-data {
    display: flex;
    cursor: pointer;
    border: 1px solid;
    padding: 5px;
    border-radius: 10px;
}
.data-presc-wrapper-box {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}
.cancel-order-repo-wrap button {
    background: #FF5959 0% 0% no-repeat padding-box;
    border-radius: 6px;
    border: none;
    padding: 5px 30px;
    font-size: 13px;
    color: white;
    font-weight: 400;
}
.cancel-order-repo-wrap {
    text-align: center;
    margin-bottom: 20px;
}
.repo-mode-text {
    margin-top: 10px;
}

.repo-total p {
    margin-bottom: 10px !important;
    height: 25px !important;
}

.ship-order-data {
    margin-bottom: 10px;
}

.shipment-name {
    font-size: 12px !important;
}

.shipment-price-text p {
    font-size: 14px !important;
}

.shipment-price-text span {
    font-size: 11px !important;
}

.ship-ellipse {
    width: 242px !important;
}

.shipment-order-summary {
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-bottom: 0px;
}

.item-summary-data-box-repo h6 {
    margin-bottom: 0px;
}

.track-image-datas img {
    width: 40px;
}

.order-shipment-data {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #4C4C4C29;
    border: 0.5px solid #0CB875;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 15px;
}

.track-image-datas {
    display: flex;
    align-items: center;
}

.completed-text {
    font-weight: 500 !important;
    color: #000 !important;
}

.step-icon img {
    width: 43px;
}

.tracking-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tracking-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.step-icon {
    /* background-color: #e0e0e0; */
    border-radius: 50%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    position: relative;
    z-index: 999;
}

.step-icon.completed {
    background-color: #28a745;
    /* Completed step color */
}

.step-name {
    margin-top: 5px;
    font-size: 12px;
    color: #666;
}

.completed-text {
    color: black;
    font-weight: bold;
}

.step-connector {
    position: absolute;
    top: 25px;
    left: 32px;
    width: 80px;
    height: 2px;
    background-color: #ccc;
}

.saving-data-p p {
    margin-bottom: 0px !important;
}
.price-payment-sum-repo.saving-data-p {
    padding: 8px 15px;
    margin-top: 10px;
    background: #20948B 0% 0% no-repeat padding-box;
    border-radius: 18px;
    /* color: white; */
}
.price-payment-sum-repo.saving-data-p p {
    color: white;
}
.user-data-repo-box p {
    font-size: 13px;
    margin-bottom: 3px;
    color: #363636;
    font-weight: 400;
}
.user-data-repo-box hr {
    margin-bottom: 8px;
    margin-top: 8px;
    color: #0CB875;
    height: 2px;
    background: #0CB875;
}
.prscrption p{
    border-bottom: none !important;
}
.order-info-head p {
    font: normal normal 500 15px/19px Nunito;
    letter-spacing: 0px;
    color: #363636;
    border-bottom: 1px dashed #007E4D;
    padding-bottom: 5px;
}
.reorder-btn-data-repo.paynow-box-btn.sticky-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
button.btn-pay-now {
    padding: 5px 30px !important;
    background: #00A263 0% 0% no-repeat padding-box !important;
}
.reorder-btn-data-repo.paynow-box-btn.sticky-bottom p {
    margin-bottom: 0px;
    font-size: 18px;
    font-weight: 500;
}
.shipment-order-cover-info {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 3px #7373731C;
    border: 1px solid #0CB875;
    border-radius: 10px;
    padding: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.completed+.step-connector {
    background-color: #28a745;
    /* Completed line color */
}