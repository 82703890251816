.packages-cover-wrapper {
    display: flex;
    justify-content: center;
}

.used-data-wrapper img {
    width: 30px;
}

.options-wrapper select {
    width: 250px;
    padding: 10px;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px #6B6B6B29;
    border: 2px solid #0CB875;
    border-radius: 11px;
    font-size: 14px;
    animation: smoothBlink 0.7s infinite ease-in-out; /* Slower & smoother */
}

@keyframes smoothBlink {
    0% { border-color: #0CB875; }
    50% { border-color: rgba(12, 184, 117, 0.3); } /* Soft fade effect */
    100% { border-color: #0CB875; }
}


/* .blinking-select {
    border: 2px solid red;
} */


.wallet-repo-wrapper {
    display: flex;
    align-items: center;
    background-color: #00a65a;
    border-radius: 12px;
    padding: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #fff;
    max-width: 350px;
    transition: transform 0.3s;
}

.shar-btn-close button {
    background: #25D366;
    border: none;
    padding: 10px;
    border-radius: 10px;
    padding-left: 11px;
    padding-right: 11px;
}

.shar-text-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.shar-btn-close button svg {
    font-size: 25px;
    fill: white;
}

.share-box-btn-repo {
    background: none;
    border: none;
    color: #ffffff;
    margin-left: 10px;
    cursor: pointer;
    font-size: 15px;
    margin-right: 2px;
    background: #006fb3;
    border-top-right-radius: 10px;
    padding-bottom: 3px;
    padding-left: 12px;
}

.shar-text-icon p {
    margin-bottom: 0px;
    font-size: 13px;
    margin-top: 8px;
}

.share-box-btn-repo:focus {
    /* background: none !important; */
    border: none !important;
}

.share-container {
    border-radius: 15px;
    display: flex;
    padding-right: 0;
    text-align: center;
    transition: width 0.5s;
    width: 80px;
    background-color: #eeeeee;
    margin-left: 20px;
}

.share-btn {
    width: 90px;
    padding: 5px;
    font-size: 11px;
    font-weight: 800;
    color: #ffffff;
    border-radius: 15px;
    background-color: #04348b;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.share-box-btn-repo svg {
    font-size: 16px;
    fill: white;
}

.share-repo-motor {
    background: white !important;
    padding-left: 0px !important;
    border-radius: 50% !important;
    font-size: 14px !important;
    padding-bottom: 0px !important;
}

.share-repo-motor svg {
    font-size: 16px;
    fill: #2a6da2 !important;
    margin-left: 3px !important;
    margin-right: -1px;
}

.compare-page-share i {
    font-size: 12px !important;
}

.share-box-btn-repo {
    margin-left: 13px;
}
.share-box {
    display: flex;
    align-items: center;
    padding: 10px;
    justify-content: space-between;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    margin-top: 10px;
    width: 95%;
    margin: 0 auto;
    margin-top: 10px;
    border-radius: 10px;
    margin-bottom: 20px;
    background: #f4fffb;
}
.share-box button {
    background: none;
    border: none;
    font-size: 13px;
    width: 85px;
}
.content-share-repo p {
    margin-bottom: 0px;
    font-size: 13px;
}
.content-share-repo h6 {
    font-size: 14px;
    margin-bottom: 4px;
}
button.gmail-color-icon img {
    width: 44px;
    height: 50px;
    padding: 0px !important;
    margin-top: -3px !important;
}

button.copy-color-icon {
    background: #003759;
}

button.gmail-color-icon {
    background: none;
    padding: 0px;
}

.shar-btn-close {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
}

.wallet-repo-wrapper:hover {
    transform: translateY(-5px);
}

.wallet-container {
    display: flex;
    align-items: center;
}

.wallet-icon {
    background-color: white;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
}

.wallet-icon img {
    width: 24px;
    height: 24px;
}

.wallet-details h3 {
    margin: 0;
    font-size: 1.1rem;
    font-weight: 600;
}

.wallet-details p {
    margin: 4px 0 0;
    font-size: 0.9rem;
}

.chnage-address-btn button {
    font: normal normal 800 12px/16px Nunito;
    letter-spacing: 0px;
    color: #007E4D;
    text-transform: uppercase;
    background: none;
    border: none;
}

.address-wrapper-box-order {
    display: flex;
    background: #EFFFF9 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #B0B0B028;
    padding: 8px;
    align-items: center;
    margin-bottom: 10px;
}
.report-data-content-box p {
    margin-bottom: 0px;
}
.view-more-reports-btn.text-center button {
    background: none;
    color: black;
    border: none;
    font-size: 13px;
    padding-bottom: 0px;
    text-decoration: underline;
    padding-top: 12px;
}
.report-btn-cover-repo-btuton.repo-modal-report-data a {
    font-size: 10px !important;
}
.report-data-content-box {
    display: flex;
}
.repo-lab-data-box.modal-body {
    padding-right: 5px !important;
}
.report-data-content-box img {
    width: 20px;
    margin-top: 0px !important;
    margin-bottom: 20px;
    margin-right: 4px;
}

.report-data-content-modal {
    display: flex;
    justify-content: space-between;
}
.report-data-content-box p {
    margin-bottom: 0px;
}
.offer-banner-packages {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}

.discount-text p {
    margin-bottom: 0px;
    font-size: 25px;
    font-weight: 500;
}

.package-discount-name p {
    margin-bottom: 0px;
    font-size: 30px;
    margin-top: 25px;
    margin-right: 25px;
    font-weight: 500;
}

.address-content-repo-text p {
    margin-bottom: 0px;
    font-size: 12px;
    font-weight: 500;
}

.address-content-box-save img {
    width: 23px;
}

.address-content-box-save {
    display: flex;
    align-items: center;
    gap: 8px;
}

.options-wrapper {
    position: relative;
    display: flex;
    width: 95%;
    align-items: center;
    gap: 5px;
}

.options-wrapper::after {
    content: '\25BC';
    position: absolute;
    top: 10px;
    right: 5px;
    padding: 0 1em;
    background: #ffffff;
    cursor: pointer;
    pointer-events: none;
    transition: .25s all ease;
    width: 32px;
    color: #0CB875;
    font-size: 14px;
}

select:focus-visible {
    outline: transparent;
}


/* From Uiverse.io by PriyanshuGupta28 */
.checkbox-wrapper input[type="checkbox"] {
    display: none;
}

.check-data-wrapper p {
    margin-bottom: 0px;
    font-size: 14px;
    color: white;
    font-weight: 600;
}

.check-data-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
}

.wallet-repo-wrapper {
    background: #009A5E 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 3px #AEA6A673;
    border-radius: 8px;
    width: 92%;
    margin: 0 auto;
    margin-top: 20px;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 8px;
}

.wrapper-text-content-box span {
    font-size: 12px;
    position: relative;
    top: -5px;
    color: white;
}

.checkbox-wrapper .terms-label .label-text {
    margin-left: 10px;
}

.checkbox-wrapper .checkbox-svg {
    width: 23px;
    height: 23px;
    cursor: pointer;
}

.used-data-wrapper p {
    margin-bottom: 0px;
    font-size: 12px;
    color: white;
}

.used-data-wrapper {
    text-align: center;
}

.checkbox-wrapper .checkbox-box {
    fill: white;
    stroke: #0cb875;
    stroke-dasharray: 800;
    stroke-dashoffset: 800;
    transition: stroke-dashoffset 0.6s ease-in;
}

.checkbox-wrapper .checkbox-tick {
    stroke: #0cb875;
    stroke-dasharray: 172;
    stroke-dashoffset: 172;
    transition: stroke-dashoffset 0.6s ease-in;
}

.checkbox-wrapper input[type="checkbox"]:checked+.terms-label .checkbox-box,
.checkbox-wrapper input[type="checkbox"]:checked+.terms-label .checkbox-tick {
    stroke-dashoffset: 0;
}