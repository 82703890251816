.doctor-grid {
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* justify-content: center; */
    align-items: center;
    margin-top: 15px;
    margin-bottom: 20px;
}

.doctor-image img {
    width: 62px;
    height: 62px;
    /* border-radius: 50%; */
    /* margin-right: 10px; */
}

.doctor-image {
    /* padding: 10px; */
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    width: fit-content;
    height: fit-content;
    border-radius: 50%;
    /* display: flex; */
    /* align-items: center; */
}

.card-header h3 {
    margin: 0;
}

.doctor-category {
    font-size: 14px;
    color: #555;
    margin-bottom: 20px;
}

.doctor-category span {
    font-weight: 500;
    color: rgb(29, 29, 29);
}

.card-actions {
    display: flex;
    justify-content: space-between;
}

.email-btn,
.call-btn {
    background-color: #f0f0f0;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.email-btn:hover,
.call-btn:hover {
    background-color: #e0e0e0;
}

.doctor-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.online-text-repo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: red;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    padding: 5px 15px;
}

.icons-box-online svg {
    background: #2f6cd3;
    padding: 5px;
    font-size: 22px;
    color: white;
    border-radius: 50%;
    margin-right: 8px;
}

.inpersone-repo svg {
    background: #198754 !important;
}

.onlinr-price p {
    margin-bottom: 0px;
    color: #383c5b;
    font-weight: 500;
}

.online-consultation-wrapper-clover {

    overflow: hidden;
    /* border-radius: 10px; */
    /* padding-bottom: 10px; */
}

.inperson-repo {
    background-color: #00ca6c9e !important;
}

.onlinr-price.inpersone-pri p {
    color: white;
}

.tabs-days-repo-clover {
    border: 1px solid #ebebeb;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-bottom: 10px;
}
.tabs-days-repo-clover ul li:focus-visible{
    outline: none !important;
}
.tabs-days-repo-clover ul li{
    border: none !important;
}
.icons-box-online.inpersone-repo {
    color: white;
}

.consultation-wrapper-clover {
    padding: 10px;
}

.doctor-card {
    background-color: #fbfdff;
    border-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgb(255 255 255 / 20%) 0px 1px 3px -1px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    width: 305px;
    padding-top: 15px;
}

.search-bar {
    margin: 15px 0;
    text-align: center;
    /* position: relative; */
    position: sticky;
    position: -webkit-sticky;
    top: 60px;
    background: white;
    padding: 2px;
    z-index: 999;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.search-bar svg {
    position: absolute;
    right: 25px;
    top: 20px;
    font-size: 20px;
    cursor: pointer;
}

.repo-cate-doc {
    background: #fff 0 0 no-repeat padding-box;
    border: 1px solid #b0b0b0;
    border-radius: 8px;
    border-radius: 9px;
    cursor: pointer;
    flex-shrink: 0;
    margin-right: 10px;
    padding: 5px 20px;
    user-select: none;
    -webkit-user-select: none;
    font-size: 12px;
    width: fit-content !important;
}

.search-bar input {
    padding: 10px;
    width: 96%;
    border-radius: 10px;
    border: 0px solid #ccc;
    font-size: 13px;
    padding-left: 15px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.filter-buttons {
    display: flex;
    margin-bottom: 15px;
    overflow-x: auto;
    padding: 10px 10px 0;
    white-space: nowrap;
    width: 100%;
    padding-top: 0px;
    margin-bottom: 0px;
    margin-top: 10px;
}

.btn-active {
    background-color: #198754;
    /* Green background for selected button */
    color: white;
    border: none;
}

.doctor-info {
    display: flex;
    flex-direction: column;
}
span.admin-tag-loki {
    font-size: 10px;
    color: #198754;
    border: 1px solid #0cb875;
    padding: 3px 10px;
    border-radius: 30px;
    font-weight: 500;
    margin-left: -2px;
}
.doctor-header {
    /* display: flex; */
    /* justify-content: space-between; */
    /* flex-direction: column; */
    text-align: start;
}

.doctor-name {
    margin: 0;
    font-size: 15px;
    font-weight: 500;
}

.admin-tag {
    background-color: #e0f7fa;
    color: #00796b;
    font-size: 11px;
    padding: 1px 8px;
    border-radius: 15px;
    margin-left: 6px;
    height: 20px;
    padding-top: 1px;
    cursor: pointer;
}

.hospital-services-repo-wrapper-clover h5 img {
    width: 21px;
    margin-right: 5px;
}

button.direction-icon.unique-direction-icon svg {
    margin-left: -3px;
}

.gmnoprint {
    display: none !important;
}

.unique-clinic-card {
    width: 100%;
    /* max-width: 400px; */
    margin: 20px auto;
    padding: 0px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    padding-bottom: 10px;
}

.unique-map-iframe .gm-style .directions-card-medium-large {
    height: 61px;
    padding: 10px 11px;
    display: none !important;
}

.unique-heading {
    font-size: 20px;
    margin-bottom: 10px;
}

.unique-map-container {
    position: relative;
    margin-bottom: 15px;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
}

.unique-map-iframe {
    width: 100%;
    height: 200px;
    border: 0;
    border-radius: 10px 10px 0px 0px;
}

.unique-direction-icon {
    color: #007bff;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 15px;
    background: transparent;
    font-weight: 500;
    text-decoration: underline;
}

.unique-direction-icon:hover {
    background: #0056b3;
}

.unique-clinic-info {
    padding: 10px 0;
    padding-top: 0px;
    /* padding-left: 10px; */
    padding-bottom: 1px;
}

.unique-clinic-name {
    margin: 10px 0;
    font-size: 16px;
    margin-bottom: 0px;
}

/* .inperson-address-repo-clinic {
    padding: 10px;
    border: 1px solid #ebebeb;
    border-bottom-width: 0px;
} */

.inperson-address-repo-clinic p {
    margin-bottom: 0px;
    font-size: 12px;
    color: dimgray;
}
.repo-swiper-click-loki img {
    width: 100%;
    margin-left: -15px;
    margin-bottom: 20px;
}
.inperson-address-repo-clinic h6 {
    margin-bottom: 3px;
    font-size: 14px;
}

.address-list {
    margin: 0 auto;
    border: 1px solid #f2f2f2;
    /* padding: 10px; */
    border-bottom-width: 0px;
    display: flex;
    flex-direction: column;
}
.repo-text-wrapper-cover-not{
        text-align: center;
        margin-top: 20px;
        font-size: 23px;
        position: absolute;
        top: 50%;
        left: 22%;
        font-weight: 500;
}

.toggle-button {
    padding: 8px 16px;
    background-color: snow;
    color: #198754;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    /* text-decoration: underline; */
    font-weight: 500;
    display: flex;
    justify-content: center;
    padding-top: 12px;
}
.checkbox-wrapper .repo-text-repo-check-box {
    width: 16px !important;
    height: 16px !important;
    cursor: pointer;
    border: 1px solid dimgray;
    margin-top: -4px !important;
}
.dropdown-toggle-button {
    background: none;
    border: none;
    color: #198754;
    cursor: pointer;
    font-size: 16px;
    /* margin-top: 10px; */
}

.hospital-card-container__additional-cards {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
}

.hospital-card-container__additional-cards--expanded {
    max-height: 500px;
    transition: max-height 0.3s ease-in;
    margin-top: 10px;
}

.repo-arrow-repo-clover {
    border-bottom: 1px solid;
    width: fit-content;
}
.repo-arrow-repo-clover svg {
    font-size: 15px;
    margin-top: -3px;
    margin-right: -3px;
}
.inperson-address-repo-clinic {
    /* margin-bottom: 12px; */
    /* align-items: center; */
    cursor: pointer;
    padding: 10px;
    padding-bottom: 0px;
}
.hospital-services-repo-wrapper-clover ul li {
    font-size: 14px;
}
.repo-bro-clover {
    /* width: 80%; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0px;
}

.price-tag-repo-doctor-repo{
    color: #198754 !important;
    border-radius: 6px;
    font-weight: 500;
    font-size: 13px !important;
}
.depart-clover-wrap {
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
    cursor: pointer;
}

.depart-clover-wrap:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgb(83 83 83 / 20%);
    background-color: #fafafabf;
}
p.repo-form-opd-tepo {
    margin-top: 6px;
    background: #198754;
    width: fit-content;
    padding: 4px 10px;
    font-weight: 400;
    color: white;
    border-radius: 20px;
    font-size: 12px;
}
.depart-clover-wrap .icon-path-rpeo img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.depart-clover-wrap:hover .icon-path-rpeo img {
    transform: scale(1.1);
}

.depart-clover-wrap p {
    margin-top: 10px;
    font-size: 14px;
    color: #333;
    transition: color 0.3s ease;
}

.depart-clover-wrap:hover p {
    color: #007bff;
}

.repo-ex-repo-clover {
    position: relative;
    padding: 10px;
    border-radius: 8px;
    transition: background 0.3s ease-in-out, transform 0.3s ease-in-out;
    width: 95%;
    margin: 0 auto;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.txt-repo-data-txt {
    padding-left: 10px;
    margin-top: 12px;
}
.dropdown-toggle-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
    color: #198754;
}

.arrow {
    display: inline-block;
    transition: transform 0.3s ease; /* Smooth transition for rotation */
}

.arrow-rotated {
    transform: rotate(180deg); /* Rotate 180 degrees */
}

.additional-clinics {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s ease;
}
.repo-txt-txt-repo {
    display: flex;
    align-items: center;
    gap: 8px;
}
.additional-clinics.expanded {
    max-height: 500px;
    /* Adjust this based on the content height */
}

.unique-clinic-address {
    margin: 5px 0;
    color: #555;
    margin-bottom: 0px !important;
}

.unique-ratings {
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.unique-rating-value {
    margin-left: 5px;
    color: #555;
}

.unique-timings {
    margin: 10px 0;
}

.unique-timings-heading {
    font-size: 14px;
    margin-bottom: 5px;
}

.unique-timings-days,
.unique-timings-hours {
    margin: 2px 0;
    margin-bottom: 0px !important;
}

.unique-status {
    background: #d4edda;
    color: #155724;
    padding: 3px 8px;
    font-size: 12px;
    border-radius: 5px;
    display: inline-block;
    margin-top: 5px;
}

.unique-contact-button {
    width: 70%;
    padding: 8px;
    background: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    margin: 0 auto;
    margin-top: 0px;
    text-align: center;
    /* margin: 0 auto; */
    justify-content: center;
    font-weight: 500;
}

.unique-contact-button:hover {
    background: #0056b3;
}

.gm-style {
    display: none !important;
}

.hospital-services-repo-wrapper-clover {
    padding-left: 15px;
    padding-top: 10px;
}

.hospital-services-repo-wrapper-clover ul {
    padding-left: 25px;
}

.hospital-services-repo-wrapper-clover h5 {
    margin-bottom: 10px;
    font-size: 16px;
}

.repo-clover-price-data img {
    width: 24px;
    /* filter: invert(1); */
}

.doctor-repo-cover {
    display: flex;
    align-items: center;
    /* border-bottom: 1px solid #bdbdbd; */
    padding-bottom: 15px;
    gap: 15px;
    cursor: pointer;
    /* border-top: 1px solid #bdbdbd;
    margin-top: 10px;
    padding-top: 15px; */
}

.fess-repo-text p {
    padding: 4px 16px;
    margin-bottom: 0px;
    background: #4a90e2;
    color: white;
    font-size: 12px;
    border-radius: 30px;
}

.doctor-data-repo {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    margin-top: 15px;
    gap: 20px;
    padding: 10px;
    position: relative;
}

.fess-repo-text {
    position: absolute;
    right: 5px;
    top: 8px;
}

button.online {
    border: none;
    padding: 7px 25px;
    font-size: 14px;
    border-radius: 5px;
}

.book-apointment-btn {
    margin-top: 10px;
    text-align: center;
}

.book-apointment-btn button {
    background: #198754;
    border: none;
    color: white;
    padding: 8px 50px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.book-apointment-btn {
    margin-top: 10px;
    text-align: center;
}

.doctor-buttons-repo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    border-bottom: 1px solid #bdbdbd;
}
p.repo-loki-address {
    text-align: start;
    font-size: 12px;
    margin-bottom: 0px;
    font-weight: 500;
}
p.repo-loki-address span {
    font-weight: 400;
}
.doctor-buttons-repo-detail {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-bottom: 1px solid #bdbdbd;
    padding-bottom: 10px;
    background: white;
    padding-top: 10px;
}

.repo-clover-tabs-repo li button:hover {
    border: 0px solid !important;
    outline: none;
}

.view-slots-repo-lover button svg {
    margin-top: -3px;
    margin-left: -1px;
}

.view-slots-repo-lover {
    text-align: center;
    margin-top: 10px;
}

.view-slots-repo-lover button {
    background: none;
    border: none;
    color: #4c7bcb;
    font-weight: 500;
    font-size: 13px;
}

.department-repo-clover-wrapper {
    padding: 15px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    padding-left: 8px;
    padding-right: 5px;
}

.depart-clover-wrap {
    padding: 10px 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    border-radius: 10px;
    padding-top: 25px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    cursor: pointer;
}

.depart-clover-wrap p {
    margin-bottom: 0px;
    font-size: 10px;
    font-weight: 500;
    text-align: center;
}

.depart-clover-wrap svg {
    font-size: 22px;
    color: white;
}

.icon-path-rpeo {
    padding: 10px;
    border-radius: 10px;
}

.slot-repo-clover p {
    color: dimgray;
    font-size: 12px;
}

.slot-repo-clover {
    border: 1px solid #b1b0b0;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
}

.repo-clover-tabs-repo li button:focus {
    border: 0px solid !important;
    outline: none;
}

.repo-clover-tabs-repo li button:focus-visible {
    border: 0px solid !important;
    box-shadow: none !important;
    outline: none;
}

.repo-clover-tabs-repo li button {
    padding: 8px 48px;
    font-weight: 500;
    font-size: 14px !important;
    margin-left: 0px !important;
}
p.repo-not-slot-aval {
    margin-bottom: 0px;
    text-align: center;
    font-size: 13px;
    margin-top: 12px;
    font-weight: 500;
}
.map-error-message {
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.data-box-image-presc {
    display: flex;
}
.data-box-image-presc img {
    width: 100%;
    margin: 0 auto;
}y
.map-error-message p {
    font-size: 17px !important;
    font-weight: 500;
    color: black !important;
}
span.depart-count {
    font-size: 11px;
    color: #0d6efd;
    font-weight: 600;
    margin-left: 3px;
}
.icons-box-online {
    display: flex;
    align-items: center;
}

.online-text-repo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #e5eefe;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    padding: 8px 15px;
    color: #8e96aa;
    font-size: 13px;
}

button.online {
    border: none;
    padding: 7px 25px;
    font-size: 14px;
    border-radius: 5px;
}

.online-btn img {
    width: 25px;
    filter: invert(1);
}

.person-consul-btn img {
    width: 25px;
    filter: invert(1);
}

.doctor-buttons-repo button {
    padding: 4px 20px;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 13px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.slot-repo-clover {
    cursor: pointer;
    padding: 7px 10px;
    text-align: center;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.slot-repo-clover.active-slot {
    background-color: #198754;
    color: white;
}

.slot-repo-clover.active-slot p {
    color: white;
}

.doctor-buttons-repo-detail button {
    padding: 8px 30px;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    font-weight: 500;
    background-color: rgb(0, 126, 77) !important;
}

.price-tag-doc p {
    margin-bottom: 0px;
    font-size: 13px;
    color: #0d6efd;
    /* margin-left: -10px; */
}
p.doc-qualification-repo {
    word-break: break-word;
    font-size: 11px;
    margin-bottom: 0px;
    font-weight: 500;
}
/* .price-tag-doc {
    background: #52c18a;
    padding: 3px 9px;
    border-radius: 15px;
} */

.repo-clover-price-data {
    /* padding-top: 10px; */
    display: flex;
    justify-content: center;
    gap: 10px;
    /* background-color: #e0f7fa; */
    align-items: center;
    padding: 8px;
    border-radius: 8px;
    /* border: 1px solid #caeee8; */
    /* padding-left: 4px; */
    /* padding-right: 4px; */
}
.price-tag-doc {
    font-size: 12px;
    font-weight: 500;
    text-align: start;
}
.online {
    font-size: 11px;
    display: flex;
    align-items: center;
}

.repo-clover-price-data img {
    width: 28px;
    margin-right: 5px;
}

.price-tag-doc p {
    margin-bottom: 0px;
}
    
.doctor-category {
    font-size: 12px !important;
    color: #555 !important;
    margin: 5px !important;
    margin-top: 3px !important;
    margin-bottom: 0px !important;
    font-weight: 400 !important;
    text-align: start !important;
}

p.doctor-category svg {
    color: black;
    margin-left: -5px;
    margin-top: -3px;
}

.doctor-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.action-btn {
    background-color: #f0f0f0;
    border: none;
    padding: 10px;
    width: 45%;
    border-radius: 5px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.action-btn:hover {
    background-color: #e0e0e0;
}

.repo-clover-price-data button {
    padding: 4px 20px;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 13px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.About-doctor-repo {
    padding-left: 15px;
}

.doctor-data-box-tepo h5 {
    font-size: 15px;
}

.repo-clover-price-data.sticky-bottom.repo-buttons-tepo {
    background: white;
    padding-top: 12px;
    padding-bottom: 12px;
    box-shadow: 0 -2px 4px -3px rgba(0, 0, 0, 0.3);
}

.search-bar input:focus-visible {
    outline: none;
}

.doctor-data-repo svg {
    color: #a3a1a1 !important;
}

.About-doctor-repo svg {
    color: #a3a1a1 !important;
}

.bio-doctor-repo {
    padding-left: 15px;
    margin-bottom: 40px;
}

.desc-text-repo p {
    font-size: 12px;
    color: #757575;
}

.online-btn {
    background-color: #4A90E2;
}

.person-consul-btn {
    background-color: #198754;
}

button i {
    font-size: 18px;
}

.doctor-tepo-image {
    padding: 0px;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; */
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ebeaea;
    position: relative;
    width: 65px;
    height: 65px;
}

.doctor-tepo-image img {
    /* padding: 10px; */
    width: 65px;
    border-radius: 50%;
}

.about-user-data {
    padding-left: 5px;
}

.experience-repo-text p {
    font-size: 14px;
    color: #1c1c1c;
    margin-bottom: 0px;
    font-weight: 500;
}

.doctor-data-box-tepo {
    width: 420px;
}

.experience-repo-text span {
    font-size: 12px;
    margin-bottom: 0px;
    /* padding-left: 20px; */
    color: #757575;
    /* width: 90%; */
}

.experience-repo-text {
    display: flex;
    gap: 5px;
}

.user-icon svg {
    margin-top: -5px;
}