.home-app-header-new.sticky-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    height: 60px;
    border-radius: 7px;
    background: transparent linear-gradient(177deg, #FFFFFF 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #B7B7B729;
    border-radius: 0px 0px 20px 20px;
}

.slider-img-repo-new {
    border-radius: 10px;
    /* Ensure consistent border-radius */
    position: relative;
    /* Needed for pseudo-element positioning */
    width: 53px;
    height: 53px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 3px 6px #E8E8E870;
    overflow: hidden;
    /* To clip the pseudo-element inside */
}

.applabtest-repo {
    display: flex !important;
}

.slider-img-repo-new::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(135deg, #19E5B2 0%, #19C4D5 100%);
    border-radius: 10px;
    z-index: -1;
    /* Ensure it stays behind the content */
    margin: -1px;
    /* Adjust for the border width */
}

.slider-img-repo-new::after {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
    background: #FFFFFF;
    border-radius: 9px;
    /* Match the inner radius */
    z-index: 0;
}

button.btn-slider-repo-new p {
    margin-top: 5px;
}

.slider-img-repo-new img {
    width: 27px;
    position: relative;
    z-index: 1;
}

.btn-slider-repo-new {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wellness-benefit-repo-new-cover {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
    margin-top: 15px;
}

.wellness-boxs-new-form {
    text-align: center;
    height: fit-content;
    cursor: pointer;
}

.repo-welless-benefit-cover-new {
    padding: 10px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #CDFFF2;

}

.denatl-box-new img {
    height: 90px;
    cursor: pointer;
}

.dental-wrapper-new {
    margin-top: 10px;
}

.wellness-boxs-new-form img {
    width: 30px;
}

.wellness-boxs-new-form p {
    font-size: 11px;
    letter-spacing: 0px;
    color: #1D0100;
    margin-top: 4px;
    font-weight: 500;
    margin-bottom: 0px;
}


/* Ambulance Css */
.custom-radio-group {
    display: flex;
    justify-content: space-around;
}

.custom-radio-container {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
}


.custom-radio-container input[type="radio"] {
    opacity: 0;
    position: absolute;
}

.custom-radio-checkmark {
    position: relative;
    height: 16px;
    width: 16px;
    border: 1px solid #198754;
    border-radius: 50%;
    transition: background-color 0.4s ease,
        transform 0.4s ease;
    margin-right: 5px;
    display: inline-block;
    vertical-align: middle;
}

.custom-radio-container input[type="radio"]:checked+.custom-radio-checkmark {
    background-color: #ffffff;
    border-color: #198754;
    /* box-shadow: 0 0 0 8px rgba(0, 123, 255, 0.2); */
    transform: scale(1.2);
    animation: pulse 0.6s forwards;
}

label.custom-radio-container p {
    margin-bottom: 0px;
    font-size: 15px;
    cursor: pointer;
}

.unique-map-iframe-new {
    width: 95%;
    height: 200px;
    border: 0;
    /* border-radius: 10px 10px 0px 0px; */
    margin: 0 auto;
    display: flex;
    border-radius: 10px;
}

.iframe-map-container iframe {
    pointer-events: none !important;
    /* Prevent interaction but make it visible */
}

.gmnoprint {
    display: none !important;
}

.gm-style .place-card-medium {
    width: auto;
    padding: 9px 11px 9px 11px;
    display: none !important;
}

.custom-radio-checkmark::after {
    content: "";
    position: absolute;
    display: none;
}

.custom-radio-container input[type="radio"]:checked+.custom-radio-checkmark::after {
    display: block;
    left: 50%;
    top: 50%;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #198754;
    transform: translate(-50%, -50%);
}
p.error-new {
    font-size: 12px;
    color: red;
    margin-bottom: 0px;
    position: relative;
    top: -10px;
    left: 3px;
}
@keyframes pulse {
    0% {
        transform: scale(1.2);
    }

    50% {
        transform: scale(1.4);
    }

    100% {
        transform: scale(1.2);
    }
}